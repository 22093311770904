import request from '@/utils/request'

export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: { token }
  })
}

export function logout() {
  let isdata = {
    currentPage:1,
    pageSize:10,
  }
  return request({
    url: '/parking/parkinglist_get',
    method: 'post',
    data:isdata
  })
}

//获取公众号二维码
export function getqRCode(data){
  return request({
    url:'/api/oauth/makeqrcode/',
    method:'post',
    data,
  })
}
//判断是否授权
export function isAuth(data) {
  return request({
    url:'/api/oauth/checkscan/',
    method:'post',
    data
    // data:{
    //   key:'gQHh8DwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyeDY0TnB4X2lkNEYxeGJhdGhCMXoAAgST-pxlAwS4CwAA'
    // }
  })
}

//超级管理员账号密码登录
export function login(data) {
  return request({
    url: '/api/oauth/login/',
    method: 'post',
    data
  })
}

