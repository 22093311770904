export const permissionList = [
    { key: "GET_FILE_LIST", desc: "获取文件列表", url: "/api2/repos/{repo-id}/dir/", method: "get" },
    // { key: "GUIDANG", desc: "归档", url: "/archive", method: "post" },
    // { key: "UPLOAD_RECORD", desc: "上传录音", url: "/record", method: "post" },
    // { key: "ADD_DEPT", desc: "添加部门", url: "/api/oauth/department/", method: "post" },
    // { key: "QUERY_DEPT", desc: "查找部门列表", url: "/api/oauth/department/", method: "get" },
    // { key: "UPDATE_DEPT", desc: "编辑部门", url: "/api/oauth/department_detail/{id}", method: "put" },
    // { key: "DELETE_DEPT", desc: "删除部门", url: "/api/oauth/department_detail/{id}", method: "delete" },
    { key: "GET_DEPT_DETAIL", desc: "查找单个部门", url: "/api/oauth/department_detail/{id}/", method: "get" },
    // { key: "ANNOUNCEMENT_LIST", desc: "公告列表", url: "/api/projects/announcement/", method: "get" },
    { key: "ADD_ANNOUNCEMENT", desc: "添加公告", url: "/api/projects/announcement/", method: "post" },
    // { key: "EDIT_ANNOUNCEMENT", desc: "修改公告", url: "/api/projects/announcement/", method: "put" },
    { key: "DELETE_ANNOUNCEMENT", desc: "删除公告", url: "/api/projects/announcement/{id}/", method: "delete" },
    // { key: "SUPPLIER_LIST", desc: "供应商列表", url: "/api/projects/supplier/", method: "get" },
    // { key: "ADD_SUPPLIER", desc: "增加供应商", url: "/api/projects/supplier/", method: "post" },
    // { key: "GET_SUPPLIER_DETAIL", desc: "获取供应商", url: "/api/projects/supplier/{id}", method: "get" },
    // { key: "DELETE_SUPPLIER", desc: "删除供应商", url: "/api/projects/supplier/{id}/", method: "delete" },
    // { key: "EDIT_SUPPLIER", desc: "更改供应商", url: "/api/projects/supplier/{id}/", method: "put" },
    // { key: "ADD_ROLE", desc: "添加角色", url: "/api/oauth/role/", method: "post" },
    // { key: "ROLE_LIST", desc: "查找角色", url: "/api/oauth/role/", method: "get" },
    // { key: "EDIT_ROLE", desc: "修改角色", url: "/api/oauth/role_detail/{id}", method: "put" },
    // { key: "DELETE_ROLE", desc: "删除角色", url: "/api/oauth/role_detail/{id}/", method: "delete" },
    // { key: "GET_ROLE_DETAIL", desc: "查找单个角色", url: "/api/oauth/role_detail/{id}/", method: "get" },
    { key: "APIS_LIST", desc: "接口列表", url: "/api/config/apis/", method: "get" },
    // { key: "GET_ARCHITECHTURE", desc: "获取组织架构", url: "/api/config/company/get_chart/", method: "get" },
    { key: "GET_TIMELINE", desc: "获取预研计划", url: "/api/config/company/get_timeline/", method: "get" },
    // { key: "EDIT_ARCHITECTURE", desc: "修改组织架构", url: "/api/config/company/post_chart/", method: "post" },
    { key: "EDIT_TIMELINE", desc: "修改预研计划", url: "/api/config/company/post_timeline/", method: "post" },
    // { key: "SUPER_ORGS", desc: "公司列表-限超级管理员", url: "/api/config/org/", method: "get" },
    // { key: "GET_ORG_DETAIL", desc: "获取公司配置信息", url: "/api/config/org_detail/", method: "get" },
    // { key: "EDIT_ORG", desc: "修改公司配置", url: "/api/config/org_detail/", method: "put" },
    // { key: "CONFIG_DEPT_MENU", desc: "配置部门菜单", url: "/api/oauth/mymenu2/add_menu_to_department/", method: "post" },
    // { key: "GET_DEPT_MENU", desc: "获取部门菜单", url: "/api/oauth/mymenu2/get_department_menu/", method: "post" },
    { key: "GET_USABLE_MENU", desc: "编辑可见菜单", url: "/api/oauth/mymenu2/get_usable_menu/", method: "post" },
    { key: "ADD_DEPT", desc: "创建部门", url: "/api/oauth/organization/add_department/", method: "post" },
    // { key: "ADD_DEPT_DEPUTY", desc: "部门添加副职", url: "/api/oauth/organization/add_deputy_to_department/", method: "post" },
    // { key: "ADD_DEPT_USER", desc: "编辑人员", url: "/api/oauth/organization/add_user_to_department/", method: "post" },
    { key: "DELETE_DEPT", desc: "删除部门", url: "/api/oauth/organization/delete_department/", method: "post" },
    { key: "EDIT_DEPT", desc: "编辑部门", url: "/api/oauth/organization/get_department_member/", method: "post" },
    // { key: "EDIT_DEPT_CANSEE", desc: "部门列表 -  可见部门用", url: "/api/oauth/organization/getall_departments/", method: "post" },
    // { key: "EDIT_DEPT_BASEINFO", desc: "编辑部门 - 负责人-名称-父级专用", url: "/api/oauth/organization/update_department/", method: "post" },
    // { key: "EDIT_DEPT_LOGO", desc: "编辑部门 - logo专用", url: "/api/oauth/organization/update_department_logo/", method: "post" },
    { key: "SUPER_ADD_COMPANY", desc: "超管创建公司", url: "/api/config/company/add/", method: "" },
    { key: "EDIT_COMPANY", desc: "修改公司", url: "/api/config/company/change/", method: "post" },
    { key: "DELETE_COMPANY", desc: "删除公司", url: "/api/config/company/delete/", method: "post" },
    { key: "ADD_COMPANY", desc: "创建公司", url: "/api/config/company/new/", method: "post" },
    { key: "PERFORMANCE_DOWNLOAD", desc: "绩效列表下载", url: "/api/projects/performance/", method: "post" },
    { key: "PERFORMANCE_RATE", desc: "绩效评分", url: "/api/projects/performance/rate/", method: "post" },
    { key: "GET_PERFORMANCE_STANDARD", desc: "团队绩效", url: "/api/projects/performance_standard/", method: "get" },
    // { key: "ADD_PERFORMANCE_STANDARD", desc: "绩效评分标准-添加", url: "/api/projects/performance_standard/", method: "post" },
    // { key: "EDIT_PERFORMANCE_STANDARD", desc: "绩效评分标准-修改", url: "/api/projects/performance_standard/{id}/", method: "put" },
    { key: "EDIT_USER_INFO", desc: "修改人员", url: "/api/oauth/usermanage/{id}/", method: "put" },
    { key: "DELETE_USER", desc: "删除人员", url: "/api/oauth/usermanage/{id}/", method: "delete" },
    // { key: "DELETE_USER_ROLE", desc: "删除人员角色", url: "/api/oauth/userrole/", method: "delete" },
    // { key: "ADD_USER_ROLE", desc: "添加人员角色", url: "/api/oauth/userrole/", method: "post" },
    { key: "ADD_TASK", desc: "添加任务", url: "/api/projects/task/", method: "post" },
    { key: "EDIT_TASK", desc: "编辑任务", url: "/api/projects/task_detail/{id}/", method: "put" },
    { key: "DELETE_TASK", desc: "删除任务", url: "/api/projects/task_detail/{id}/", method: "delete" },
    { key: "TASK_ORDER", desc: "任务排序", url: "/api/projects/taskchange/order/", method: "post" },
    { key: "TASK_PAUSE", desc: "任务暂停", url: "/api/projects/taskpause/pause/", method: "post" },
    { key: "ADD_ITERATION_TASK", desc: "迭代任务-新建", url: "/api/projects/taskrepeat/repeat/", method: "post" },
    { key: "CLOSE_ABNORMAL_TASK", desc: "关闭异常任务", url: "/api/projects/abnormal/read/", method: "post" },
    // { key: "ADD_REPORT", desc: "增加报告", url: "/api/projects/taskreport/", method: "post" },
    // { key: "DELETE_REPORT", desc: "删除报告", url: "/api/projects/taskreport/{id}/", method: "delete" },
    // { key: "EDIT_REPORT", desc: "更改报告", url: "/api/projects/taskreport/{id}/", method: "put" },
    // { key: "WARNING_ABNORMAL", desc: "异常任务提醒-正在处理+已完成", url: "/api/projects/abnormal/process/", method: "post" },
    // { key: "WARNING_ABNORMAL_APPROVE", desc: "提醒9-同意", url: "/api/projects/notice/approve/", method: "post" },
    // { key: "WARNING_ABNORMAL_DISAPPROVE", desc: "提醒9-驳回", url: "/api/projects/notice/disapprove/", method: "post" },
    // { key: "CHANGE_TASK_APPROVE", desc: "变更申请任务提醒-同意", url: "/api/projects/taskchange/approve/", method: "post" },
    // { key: "CHANGE_TASK_DISAPPROVE", desc: "变更申请任务提醒-驳回", url: "/api/projects/taskchange/disapprove/", method: "post" },
    // { key: "CHANGE_TASK_EDIT", desc: "变更申请任务提醒-点编辑", url: "/api/projects/taskchange/change_get/", method: "post" },
    // { key: "FINISH_TASK_DISAPPROVE", desc: "任务完成-驳回", url: "/api/projects/taskfinish/disapprove/", method: "post" },
    // { key: "FINISH_TASK_RATE", desc: "任务完成-评分", url: "/api/projects/taskfinish/rate/", method: "post" },
    // { key: "ADD_TIMEEVENT", desc: "添加事件", url: "/api/projects/timeevent/", method: "post" },
    { key: "DELETE_TIMEEVENT", desc: "删除节点", url: "/api/projects/timeevent/", method: "delete" },
    { key: "EDIT_TIMEEVENT", desc: "编辑事件", url: "/api/projects/timeevent_detail/{id}/", method: "put" },
    // { key: "DELETE_TIMEEVENT", desc: "删除事件", url: "/api/projects/timeevent_detail/{id}/", method: "delete" },
    // { key: "TIMEEVENT_EXCLUDE_USER", desc: "事件列表-oneday -人员移除", url: "/api/projects/timeevent-dot/exclude/", method: "post" },
    // { key: "ADD_FILETYPE", desc: "文件类型-增", url: "/api/projects/filetype/", method: "post" },
    // { key: "DELETE_FILETYPE", desc: "文件类型-删", url: "/api/projects/filetype/{id}/", method: "post" },
    // { key: "EDIT_FILETYPE", desc: "文件类型-改", url: "/api/projects/filetype/{id}/", method: "put" },
    { key: "ADD_PROJECT", desc: "添加项目", url: "/api/projects/project/", method: "post" },
    { key: "EDIT_PROJECT", desc: "编辑项目", url: "/api/projects/project_detail/{id}/", method: "put" },
    { key: "DELETE_PROJECT", desc: "删除项目", url: "/api/projects/project_detail/{id}/", method: "delete" },
    { key: "PROJECT_PAUSE", desc: "项目暂停", url: "/api/projects/projectpause/pause/", method: "post" },
    { key: "PROJECT_RESUME", desc: "项目恢复", url: "/api/projects/projectpause/resume/", method: "post" },
    // { key: "ADD_NOTE", desc: "新建笔记", url: "/api/projects/memo/", method: "post" },
    // { key: "EDIT_NOTE", desc: "分享笔记-and修改", url: "/api/projects/memo/{id}/", method: "put" },
    // { key: "DELETE_NOTE", desc: "删除笔记", url: "/api/projects/memo/{id}/", method: "delete" },
    { key: "SET_ARCHIVE", desc: "设置归档", url: "/api/projects/files/archive/", method: "post" },
    // { key: "CANCEL_ARCHIVE", desc: "取消归档", url: "/api/projects/files/cancel_archive/", method: "post" },
    { key: "ARCHIVE_DOWNLOAD", desc: "归档文件下载", url: "/api/projects/files/download/", method: "post" },
    { key: "PROJECT_LOCK", desc: "归档文件-权限上锁", url: "/api/projects/files/lockproject/", method: "get" },
    // { key: "PROJECT_UNLOCK", desc: "归档文件-权限申请解锁", url: "/api/projects/files/unlock/", method: "post" },
]