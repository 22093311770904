import $router from "@/route";
import { getDynamicRoutesMenus } from "@/utils/auth";
import { fnAddDynamicMenuRoutes } from "@/route/getAsyncRouter";
export function handleFirstJump() {
  const menuCache = getDynamicRoutesMenus();
  if (!menuCache) {
    return
  }
  // 没有任何菜单时跳转至个人信息页面
  if (menuCache.length == 0) {
    $router.push({ name: "pm-userInfo" });
    return;
  }
  const dynamicRoutesCache = fnAddDynamicMenuRoutes(menuCache);
  // 取第一个路由地址跳转
  const firstRoutePath = dynamicRoutesCache[0]?.path;
  const targetPath = /^\/\d+$/.test(firstRoutePath)
    ? `${firstRoutePath}/index`
    : firstRoutePath;
  targetPath && $router.push(targetPath);
}
