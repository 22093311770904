<template>
  <div v-if="!item.hidden && item.children" class="menu-wrapper">
    <!-- 只有一个子任务显示 -->
    <router-link class="menu-item-box" v-if="
      hasOneShowingChild(item.children) &&
      !onlyOneChild.children &&
      !item.alwaysShow
    " :to="resolvePath(onlyOneChild.path)">
      <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
        <img class="department-logo"
          v-if="onlyOneChild.meta && onlyOneChild.meta.icon && onlyOneChild.meta.icon.startsWith('pmSysIcon')"
          :src="`${STATIC_OSS_PATH_PREFIX}/assets/sysIcon/${onlyOneChild.meta.icon}.svg`" alt="" :draggable="false" />
        <svg-icon class="department-logo" v-else-if="onlyOneChild.meta && onlyOneChild.meta.icon"
          :icon-class="onlyOneChild.meta.icon"></svg-icon>
        <img class="department-logo" v-else :src="`${STATIC_OSS_PATH_PREFIX}/assets/sysIcon/department.svg`" alt="" :draggable="false" />
        <span v-if="onlyOneChild.meta && onlyOneChild.meta.title" slot="title">{{ onlyOneChild.meta.title }}</span>
      </el-menu-item>
    </router-link>
    <!-- 只有多个子任务显示 -->
    <el-submenu v-else :index="item.name || item.path">
      <!-- 父主题 -->
      <template slot="title">
        <img class="department-logo" v-if="item.meta && item.meta.icon && item.meta.icon.startsWith('pmSysIcon')"
          :src="`${STATIC_OSS_PATH_PREFIX}/assets/sysIcon/${item.meta.icon}.svg`" alt="" :draggable="false" />
        <svg-icon class="department-logo" v-else-if="item.meta && item.meta.icon"
          :icon-class="item.meta.icon"></svg-icon>
        <img class="department-logo" v-else :src="`${STATIC_OSS_PATH_PREFIX}/assets/sysIcon/department.svg`" alt="" :draggable="false" />
        <span v-if="item.meta && item.meta.title" slot="title">{{
          item.meta.title
        }}</span>
        <i v-if="item.meta.cansee" class="pm-iconfont pm-icon-kejian" style="margin-left: 4px;"></i>
      </template>
      <template v-for="(child, index) in item.children" v-if="!child.hidden">
        <sidebar-item :is-nest="true" class="nest-menu" v-if="child.children && child.children.length > 0" :item="child"
          :key="child.path" :base-path="resolvePath(child.path)"></sidebar-item>

        <router-link v-else :to="resolvePath(child.path)" :key="child.name" class="menu-item-box">
          <el-menu-item :index="resolvePath(child.path)" class="item_radius">
            <svg-icon v-if="
              child.meta &&
              child.meta.icon &&
              !child.meta.icon.includes('pm-icon')
            " :icon-class="child.meta.icon"></svg-icon>
            <i v-else-if="
              child.meta &&
              child.meta.icon &&
              child.meta.icon.includes('pm-icon')
            " :class="`pm-iconfont ${child.meta.icon}`"></i>
            <div class="title-box">
              <span v-if="child.meta && child.meta.title">{{ child.meta.title }}</span>
              <span class="text" v-if="child.meta && child.realCount > 0">{{ child.realCount }}</span>
            </div>
          </el-menu-item>
        </router-link>
      </template>
    </el-submenu>
  </div>
</template>

<script>
import path from "path";
import { STATIC_OSS_PATH_PREFIX } from "@/constants";

export default {
  name: "SidebarItem",
  props: {
    // route配置json
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      onlyOneChild: null,
      STATIC_OSS_PATH_PREFIX
    };
  },
  methods: {
    hasOneShowingChild(children) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // temp set(will be used if only has one showing child )
          this.onlyOneChild = item;
          return true;
        }
      });
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
    resolvePath(...paths) {
      return path.resolve(this.basePath, ...paths);
    },
  },
};
</script>

<style lang="scss" scoped>
.router-link-exact-active {
  li {
    color: var(--pm-main-menu-active-color) !important;

    &::before {
      content: "";
      display: block;
      width: 3px;
      height: 16px;
      background: var(--pm-main-menu-active-color);
      position: absolute;
      left: 9px;
      top: 7px;
      border-radius: 10px;
    }
  }
}

.menu-item-box:last-child {
  margin-bottom: 8px;
}

.menu-item-box {
  display: inline-flex;

  li {
    font-weight: 700;
    background-color: transparent !important;
    color: rgba(16, 50, 104, 0.6) !important;
    opacity: 0.8;
    position: relative;
    
    .is-active {
      color: var(--pm-main-menu-active-color) !important;
    }
    
    .title {
      margin-left: 16px;
    }

    .pm-iconfont {
      font-size: 14px;
    }
  }
}

/deep/ .el-menu--inline {
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% - 24px);
    background: rgba(13, 104, 253, 0.1);
    position: absolute;
    left: 10px;
    top: 8px;
  }
}
</style>
