<template>
  <div class="users-container">
    <div class="cont_top">
      <div class="box" v-infinite-scroll>
        <div class="boxTitle">
          <span>选择人员</span>
          <el-checkbox v-model="isCheckedAll">全选</el-checkbox>
        </div>
        <div class="search">
          <el-input
            placeholder="输入关键字"
            suffix-icon="el-icon-search"
            v-model="keyword"
            @keyup.enter.native="searchUser"
          ></el-input>
        </div>
        <div class="box-cont">
          <div class="box-item" v-for="val in userList" :key="val.uid">
            <el-checkbox
              :label="val.name"
              :key="val.uid"
              v-model="val.checked"
              @change="handleChange(val.checked)"
            >
              <div class="user">
                <div class="avatar">
                  <img :src="val.headimg" alt />
                </div>
                <span>{{ val.name }}</span>
              </div>
            </el-checkbox>
          </div>
        </div>
      </div>
      <div class="box—right">
        <div class="boxTitle">已选人员</div>
        <div class="box">
          <div class="box-item" v-for="val in checkedUsers" :key="val.uid">
            <el-checkbox
              :label="val.label"
              :key="val.label"
              v-model="val.checked"
              @change="selectCheckedUsersChange(val.checked)"
            >
              <div class="user">
                <div class="avatar">
                  <img :src="val.headimg" alt />
                </div>
                <span>{{ val.name }}</span>
              </div>
            </el-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="cont_bottom">
      <div class="cont_bottom_right">
        <el-button round @click.stop="closeMask" class="cancel-btn">取消</el-button>
        <el-button type="primary" @click.stop="submit" round v-debounce
          >确定</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getAllUsers } from "@/api/user";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userList: [],
      checkedUsers: [],
      keyword: null,
    };
  },
  computed: {
    isCheckedAll: {
      get() {
        return this.checkedUsers.length && this.checkedUsers.length === this.userList.length;
      },
      set(value) {
        if (value) {
          this.userList.forEach((item) => {
            item.checked = true;
            this.checkedUsers.push(item);
          });
        } else {
          this.userList.forEach((item) => {
            item.checked = false;
          });
          this.checkedUsers = [];
        }
      }
    }
  },
  mounted() {
    this.$eventHub.$on("close-choose-user", this.handleUpdateShow);
  },
  beforeDestroy() {
    this.$eventHub.$off("close-choose-user", this.handleUpdateShow);
  },
  methods: {
    searchUser() {
      this.queryAllUsers({ key: this.keyword });
    },
    queryAllUsers(params) {
      getAllUsers(params)
        .then((res) => {
          let list = res.data.map((el) => {
            el.show = true;
            return el;
          });
          
          this.userList = list;
          // 更新弹出框位置
          this.$emit("updatePop");
          this.checkeduser(this.List);
        })
        .catch((err) => {});
    },
    checkeduser(arr) {
      if (arr.length) {
        this.userList.forEach((item) => {
          item.userlist.forEach((el) => {
            arr.forEach((obj) => {
              if (el.uid === obj.uid) {
                el.checked = true;
                this.checkedUsers.push(el);
              }
            });
          });
        });
      }
    },
    //关闭弹框
    closeMask() {
      this.$emit("update:show", false);
      this.$emit("close");
    },
    // 选中的人员
    handleChange() {
      this.checkedUsers = [];
      this.userList.forEach((item) => {
        if (item.checked && this.checkedUsers.indexOf(item) == -1) {
          this.checkedUsers.push(item);
        }
      });
    },
    //选择后的人员列表操作
    selectCheckedUsersChange(val, index) {
      this.handleChange();
    },
    submit() {
      this.$emit("addNewUsers", this.checkedUsers);
      this.closeMask();
    },
    handleUpdateShow() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/element-form.scss";
.users-container {
  position: relative;
  overflow: hidden;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px 0px rgba(65, 69, 74, 0.1);
  .cont_top {
    width: 100%;
    // border: 1px solid #000;
    display: flex;
    .box::-webkit-scrollbar {
      display: none;
    }
    .box {
      overflow: auto;
      box-sizing: border-box;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      width: 100%;
      .boxTitle {
        font-weight: bold;
        color: rgba(16, 50, 104, 0.6);
        font-size: 12px;
        padding: 12px 0 7px 17px;
        position: sticky;
        top: 0;
        z-index: 9;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .search {
        padding: 4px 8px;
        background: #f4f9ff;
        /deep/ .el-input__inner {
          background: #fff;
          height: auto;
          // border-radius: 20px;
        }
      }
      // border: 1px solid red;
    }
    .box—right {
      width: 100%;
      // border: 1px solid #000;
      .boxTitle {
        font-weight: bold;
        color: rgba(16, 50, 104, 0.6);
        font-size: 12px;
        padding: 12px 0 7px 17px;
      }
      .box {
        max-height: 297px;
      }
    }
  }
  .cont_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid #000;
    padding: 12px 16px;
    border-top: 1px solid rgba(16, 50, 104, 0.1);
  }
}
.colse {
  // border: 1px solid #000;
  padding-left: 25px;
}
.box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 7px 8px 16px;
  // border: 1px solid #000;
}
.box-cont {
  background: #f4f9ff;
}
.box-item {
  padding: 8px 7px 8px 16px;
  // border: 1px solid #000;
  .user {
    display: flex;
    align-items: center;
    .avatar {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.svg-icon {
  width: 16px;
  height: 16px;
  display: block;
}
::v-deep .el-checkbox {
  // border: 1px solid red;
  display: flex;
  align-items: center;
}
::v-deep .el-checkbox__inner {
  border-radius: 3px;
  width: 16px;
  height: 16px;
}
::v-deep .el-checkbox__label {
  color: #103268;
  font-size: 12px;
  font-weight: 600;
  padding-left: 8px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #103268;
}
::v-deep .el-checkbox__inner::after {
  left: 5px;
}
::v-deep .cont_bottom_right {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .cont_bottom_right .el-button {
  border: none;
  padding: 0;
  font-size: 10px;
  margin: 0;
  width: 96px;
  height: 24px;
  background: linear-gradient(
    90deg,
    rgba(73, 167, 255, 0.1) 0%,
    rgba(71, 119, 255, 0.1) 100%
  );
  color: var(--pm-main-menu-active-color);
}
::v-deep .cont_bottom_right .el-button--primary {
  // background: #000;
  background: linear-gradient(90deg, #49a7ff 0%, #4777ff 100%);
  color: #fff;
}
::v-deep .cont_bottom_right .el-button + .el-button {
  margin-left: 16px;
  &:hover {
    opacity: 0.85;
  }
}

/deep/ .el-input__inner {
  height: 24px !important;
  line-height: 24px !important;
  border-radius: 12px;
  font-size: 12px;
}

/deep/ .el-input__icon {
  line-height: 26px !important;
}
.box, .box—right {
  max-height: calc(100vh / 3);
  min-height: 100px;
  @include hideScrollbar;
}
</style>
