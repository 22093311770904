<template>
    <my-modal ref="modalRef" width="448px" title="驳回原因" @confirm="handleConfirm" inline label-width="10px">
        <el-form :model="formData" ref="formRef" :rules="rules">
            <el-form-item prop="reason">
                <el-input class="input" type="textarea" :rows="3" placeholder="请输入驳回原因" v-model.trim="formData.reason"
                    resize="none" />
            </el-form-item>
        </el-form>
    </my-modal>
</template>

<script>
import MyModal from "@/components/customElDialog/index.vue";
export default {
    components: { MyModal },
    data() {
        return {
            commitCallback: null,
            formData: {
                id: null,
                reason: ""
            },
            rules: {
                reason: [
                    { required: true, message: "请输入驳回原因", trigger: "blur" }
                ]
            }
        }
    },
    methods: {
        open(payload = null, cb) {
            this.$refs.modalRef.open();
            this.formData.id = payload;
            this.commitCallback = cb;
        },
        handleConfirm(close) {
            this.$refs.formRef.validate(valid => {
                if (valid) {
                    this.commitCallback(this.formData.id, this.formData.reason);
                    if (typeof close === "function") {
                        close();
                    }
                }
            });
        }
    }

}
</script>

<style></style>