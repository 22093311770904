import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken  , setName } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),//用户登录的Token
    name: '',//用户名
    avatar: 'https://img.zcool.cn/community/01dd395f1aaf93a801215aa0e01515.gif',//用户头像
    roles: "",//用户的角色权限
    menus:'',  //用户菜单
    audit:0,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_MENUS: (state, menus) =>{
      state.menus = menus
    },
    SET_AUDIT: (state , audit) =>{
      state.audit = audit
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        login(username, userInfo.password).then(response => {
          const data = response
          console.log(response)
          setToken(data.token)
          setName(data.name)
          commit('SET_TOKEN', data.token)
          commit('SET_NAME', data.username)
          commit('SET_ROLES', data.username)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          const data = response.data
          if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data.roles)

          } else {
            reject('getInfo: roles must be a non-null array !')
          }
          commit('SET_NAME', data.name)
          commit('SET_AVATAR', data.avatar)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then((response) => {
          console.log(response)
          // commit('SET_TOKEN', '')
          // commit('SET_ROLES', [])
          // removeToken()
          // resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
