import router from "@/route/index";
import { fnAddDynamicMenuRoutes } from "@/route/getAsyncRouter";
import { getDynamicRoutesMenus, getToken } from "@/utils/auth";
import $store from "@/store";

// 导航守卫
router.beforeEach(async (to, from, next) => {
  // 判断该路由是否需要登录权限
  if (getToken()) {
    //获取缓存路由数据
    const dynamicRoutesMenusCache = getDynamicRoutesMenus();
    if (!dynamicRoutesMenusCache) {
      await $store.dispatch("permission/queryDynamicRoutes");
      myNext(to, next, true);
    } else if (!$store.state?.permission?.hasSetDynamicRouter) {
      const dynamicRoutesCache = fnAddDynamicMenuRoutes(
        dynamicRoutesMenusCache
      );
      dynamicRoutesCache.forEach((item) => {
        router.addRoute(item);
        router.options.routes.push(item);
      });
      $store.commit("permission/SET_HASSETDYNAMICROUTER", true);
      // 查询所有权限
      if (!$store.state?.permission?.formatAllPermissions) {
        await $store.dispatch("permission/queryIHavePermissions");
      }
      myNext(to, next, true);
    } else {
      // 查询所有权限
      if (!$store.state?.permission?.formatAllPermissions) {
        await $store.dispatch("permission/queryIHavePermissions");
      }
      myNext(to, next);
    }
  } else {
    // 未登录,跳转到登陆页面
    if (to.path !== "/login") {
      if (to.path == "/login-admin") {
        next();
      } else {
        next({
          path: "/login",
        });
      }
    } else {
      next();
    }
  }
});

function myNext(to, next, replace) {
  if (to.path == "/login") {
    next({
      path: "/index",
    });
  } else {
    if (!replace) {
      next();
    } else {
      next({ ...to, replace: true });
    }
  }
}
