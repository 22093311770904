import { constantRoutes } from '@/route'
import { fnAddDynamicMenuRoutes } from '@/route/getAsyncRouter'
import { mymenu } from '@/api/auth'
import { setDynamicRoutesMenus } from '@/utils/auth'
import router from '@/route/index'
import { MY_ORGANIZATION, MY_DEPARTMENT, MY_SETTINGS } from "@/constants/storageKey"
import {
  getAllPermissions,
  getIHavePermissions,
} from "@/api/permission"
import { permissionList } from "@/constants/permissionList"

// import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: [],
  dynamicRoutes: null,
  hasSetDynamicRouter: false,
  allPermissions: null,
  formatAllPermissions: null // 格式化后的所有权限，用于dom指令权限判断
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_DYNAMICROUTES: (state, routes) => {
    state.dynamicRoutes = routes
  },
  SET_HASSETDYNAMICROUTER: (state, hasSetDynamicRouter) => {
    state.hasSetDynamicRouter = hasSetDynamicRouter
  },
  SET_ALLPERMISSIONS(state, list) {
    state.allPermissions = list
  },
  SET_FORMATALLPERMISSIONS(state, list) {
    const mapArr = new Map()
    for (let i = 0; i < permissionList.length; i++) {
      const item = permissionList[i]
      if (list.find(val => val.url === item.url && val.method === item.method)) {
        mapArr.set(item.key, item.desc)
      } else {
        continue
      }
    }
    if (mapArr.size > 0) {
      state.formatAllPermissions = mapArr
    } else {
      state.formatAllPermissions = new Map([])
    }
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      let accessedRoutes
      if (roles.includes('admin')) {
        accessedRoutes = asyncRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
  queryDynamicRoutes({ commit }) {
    return new Promise(resolve => {
      mymenu().then(res => {
        const { code, data } = res ?? {}
        if (code == 200) {
          router.options.routes = []
          const { menu, my_org, my_department, settings } = data;
          sessionStorage.setItem(MY_ORGANIZATION, JSON.stringify(my_org));
          sessionStorage.setItem(MY_DEPARTMENT, JSON.stringify(my_department));
          sessionStorage.setItem(MY_SETTINGS, JSON.stringify((settings ?? []).map(item => item.path)));
          setDynamicRoutesMenus(menu);
          const menuRoutes = fnAddDynamicMenuRoutes(menu);
          menuRoutes.forEach(item => {
            router.addRoute(item);
            router.options.routes.push(item);
          })
          commit('SET_DYNAMICROUTES', menuRoutes)
          commit("SET_HASSETDYNAMICROUTER", true)
          resolve(menuRoutes)
        } else {
          commit('SET_DYNAMICROUTES', null)
          resolve([])
        }
      })
    })
  },
  /**
   * 查询所有权限
   */
  queryAllPermissions({ commit }) {
    return new Promise(resolve => {
      getAllPermissions().then(res => {
        const { data } = res ?? {}
        commit('SET_ALLPERMISSIONS', data?.results || [])
        resolve(data?.results || [])
      })
    })
  },
  /**
   * 根据token查询当前用户拥有的权限
   */
  queryIHavePermissions({ commit }) {
    return new Promise(resolve => {
      getIHavePermissions().then(res => {
        const { data } = res ?? {}
        commit('SET_FORMATALLPERMISSIONS', data?.results || [])
        resolve(data?.results || [])
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
