<template>
  <div class="container">
    <div class="title">任务驳回</div>
    <el-form class="form" label-width="60px" :model="form">
      <el-form-item label="项目名称" prop="title">
        <el-input class="input" v-model="form.projectname" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="任务名称" prop="title">
        <el-input class="input" v-model="form.taskname" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="驳回原因">
        <el-input
          class="input"
          type="textarea"
          :rows="3"
          placeholder="请输入驳回原因"
          v-model="form.comment"
          resize="none"
        ></el-input>
      </el-form-item>
      <div v-if="!isCreate">
        <el-form-item label="负责人" prop="manager">
          <div class="persons">
            <ChoosePerson :userlist.sync="form.user" :limit="1"></ChoosePerson>
          </div>
        </el-form-item>

        <el-form-item label="变更结束时间" v-if="!checked">
          <el-date-picker
            type="date"
            placeholder
            v-model="form.new_plan_end_time"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>

        <div class="check-box">
          <el-checkbox v-model="checked">创建迭代任务</el-checkbox>
        </div>
      </div>
      <div v-if="checked">
        <el-form-item label="开始时间" prop="times">
          <el-date-picker
            class="date-picker"
            v-model="form.times"
            type="daterange"
            range-separator="结束时间"
            start-placeholder="请选择"
            end-placeholder="请选择"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <div v-if="form.times">
          <div v-for="(item) of timeevents" :key="item.id">
            <el-form-item label="事件内容" prop>
              <el-input class="input" v-model="item.name"></el-input>
            </el-form-item>
            <el-form-item label="事件节点" prop>
              <el-date-picker
                type="date"
                placeholder
                value-format="yyyy-MM-dd"
                v-model="item.event_time"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="附件">
              <div class="upload-box">
                <UploadTool :newFiles.sync="item.uploadfiles"></UploadTool>
              </div>
            </el-form-item>
          </div>
          <div class="line">
            <div class="add-task" @click="addEventPoint">+ 添加事件</div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <div @click="cancel" class="cancel">取消</div>
        <div @click="submit" class="submit">确定</div>
      </div>
    </el-form>
  </div>
</template>
      
    
<script>
import ChoosePerson from "@/components/Task/choosePerson.vue";
import UploadTool from "@/components/Task/uploadTool.vue";
import { rejectChangeTask, rejectFinishedTask } from "@/api/task";
import { Message } from "element-ui";
export default {
  components: { ChoosePerson, UploadTool },
  name: "RejectTask",
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          times: []
        };
      }
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      timeevents: [
        {
          event_time: "",
          name: "",
          uploadfiles: []
        }
      ],
      checked: false
    };
  },
  computed: {
    pickerOptions() {
      let _this = this;
      return {
        disabledDate(time) {
          const start_time = _this.form.times[0] + " 00:00:00";
          const end_time = _this.form.times[1] + " 00:00:00";
          console.log("time..........", start_time, end_time);
          return (
            time.getTime() < new Date(start_time).getTime() ||
            time.getTime() > new Date(end_time).getTime()
          );
        }
      };
    },
    isCreate() {
      return true;
      return this.type === "createTask";
    }
  },
  mounted() {
    console.log("from...", this.form);
  },
  methods: {
    addEventPoint() {
      this.timeevents.push({
        event_time: "",
        name: "",
        uploadfiles: []
      });
    },
    cancel() {
      this.form.comment = "";
      this.$emit("update:show", false);
    },
    submit() {
      if (!this.form.comment) {
        Message.error("请输入驳回原因");
        return;
      }
      {
        //处理事件节点
        let pass = true;
        this.form.timeevents = this.timeevents.map(el => {
          let active = el.event_time?.length > 0 || el.name?.length > 0;
          if (active && (el.event_time?.length == 0 || !el.name)) {
            pass = false;
          }
          return el;
        });
        if (!pass) {
          Message.error("请完善事件节点信息");
          return;
        }
        if (this.form.timeevents[0].name?.length == 0) {
          this.form.timeevents.shift();
        }
      }
      this.form.noticeid = this.form.id;
      this.form.change_plan = 0;
      if (Array.isArray(this.form.users) && this.form.users.length > 0) {
        this.form.users = this.form.users.map(e => e.uid);
      }
      this.form.change_plan = this.checked ? 1 : 0;
      console.log("this.form...", this.form);
      if (this.form.finished) {
        this.form.users = this.form.user.map(e => e.uid);
        rejectFinishedTask(this.form)
          .then(res => {
            Message.success("任务变更申请已驳回");
            this.$emit("refreshNotices");
            this.form.comment = "";
            this.$emit("update:show", false);
            this.form.noticeid = this.form.id;
          })
          .catch(err => {});
      } else {
        rejectChangeTask(this.form)
          .then(res => {
            Message.success("任务变更申请已驳回");
            this.$emit("refreshNotices");
            this.form.comment = "";
            this.$emit("update:show", false);
            this.form.noticeid = this.form.id;
          })
          .catch(err => {});
      }
    }
  }
};
</script>
    
<style lang="scss" scoped>
.persons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .person {
    height: 36px;
    line-height: 36px;
    padding: 2px 16px 2px 40px;
    color: #103268;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    border-radius: 18px;
    background: #f4f9ff;
    margin-right: 16px;
    user-select: none;
    position: relative;
    .icon {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      position: absolute;
      left: 2px;
      top: 2px;
    }
  }
  .add-user {
    width: 24px;
    height: 24px;
    line-height: 22px;
    border-radius: 12px;
    border: 1px dashed rgba(24, 110, 255, 0.2);
    text-align: center;
    font-size: 20px;
    font-weight: 100;
    color: #3989ff;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
  }
  .person:hover::after {
    content: "+";
    width: 14px;
    height: 14px;
    line-height: 15px;
    display: block;
    position: absolute;
    text-align: center;
    border-radius: 7px;
    font-size: 12px;
    transform: rotate(45deg);
    top: 0;
    right: -4px;
    color: #fff;
    background: #103268;
    opacity: 0.4;
    cursor: pointer;
  }
}
.check-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 54px;

  color: #103268;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  //   opacity: 0.6;
  margin-bottom: 12px;

  .check {
    margin: 12px 0;
    font-size: 24px;
  }
}

.line {
  height: 1px;
  width: 100%;
  background: rgba(24, 110, 255, 0.05);
  position: relative;
  margin-top: 30px;

  .add-task {
    position: absolute;
    top: -12px;
    right: 0;
    height: 24px;
    padding: 0 12px;
    border-radius: 12px;
    color: #0d68fd;
    font-size: 10px;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    background: linear-gradient(
      90deg,
      rgba(73, 167, 255, 0.1) 0%,
      rgba(71, 119, 255, 0.1) 100%
    );
    cursor: pointer;
    user-select: none;
  }
}
</style>