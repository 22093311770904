import request from '@/utils/request'

//获取角色列表
export function userRoleusers() {
    return request({
        url: '/api/oauth/roleusers/',
        method: 'get',
    })
}

//删除这个用户的角色 'delete' 
export function deleteRoleusers(uid, id) {
    return request({
        url: '/api/oauth/userrole/',
        method: 'delete',
        data: {
            uid: uid,
            rid: id
        }
    })
}
// 添加角色 'post'/

export function addRoleusers(uid, id) {
    return request({
        url: '/api/oauth/userrole/',
        method: 'post',
        data: {
            uid: uid,
            rid: id,
            is_approve: 1, // 0为未审核，1为审核完成 只要给用户添加角色，就相当于审核完成
        }
    })
}

//获取动态路由，根据权限返回
// export function mymenu(){
//     return request({
//         url:'/api/oauth/mymenu/',
//         method:"get"
//     })
// }

export function mymenu() {
    return request({
        url: '/api/oauth/mymenu2/mymenu/',
        method: "post"
    })
}


export function getSeaFileToken() {
    return request({
        url: '/api/oauth/seafile_token/',
        method: "get"
    })
}

export function getRouteCount(data) {
    return request({
        url: '/api/oauth/mymenu2/get_count/',
        method: "post",
        data
    })
}
export function getMenuItemCount(data) {
    return request({
        url: '/api/oauth/mymenu2/get_count/',
        method: "post",
        data
    })
}



