<template>
  <div @click.stop>
    <UserListModalBox
      v-if="showMask"
      :show.sync="showMask"
      :mask="false"
      @addUsers="addUsers"
    ></UserListModalBox>
    <div class="editor-box">
      <div
        class="editor"
        ref="divRef"
        contenteditable
        @blur="blurFun($event, input)"
        @focus="focus"
        :default-placeholder="input ? '' : placeholder"
        :placeholder="placeholder"
        @input="contentCheng"
        @keyup="handkeKeyUp"
        :style="style"
      ></div>
      <el-upload
        class="upload-demo"
        action="#"
        list-type="picture-card"
        :show-file-list="false"
        :auto-upload="false"
        :multiple="true"
        :file-list="upload.uploadfiles"
        :on-change="handleChange"
      >
        <svg-icon icon-class="picture_icon"></svg-icon>
      </el-upload>
    </div>
    <FileType
      :Files.sync="upload.uploadfiles"
      @deleteFile="deleteFile"
      :Type="1"
    ></FileType>
    <div class="rightBtn" v-if="input.length > 0">
      <el-button type="primary" size="mini" @click="cancel" round
        >取消</el-button
      >
      <el-button
        type="primary"
        size="mini"
        @click="addComments"
        round
        v-debounce
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import UserListModalBox from "@/components/User/userListModalBox.vue";
import FileType from "@/components/displayFileType.vue";
import { uploadBigFile } from "@/utils/bigFile";
import { keepLastIndex, validateBrowser } from "@/utils/index";

const IS_CHROME = validateBrowser();
export default {
  components: { UserListModalBox, FileType },
  props: {
    d: {
      type: Object,
      default: null,
    },
    bgColor: {
      type: String,
      default: "#fff",
    },
    autoat: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      input: "",
      placeholder: "添加评论并@成员",
      showMask: false,
      users: [],
      upload: {
        uploadfiles: [],
      },
    };
  },
  computed: {
    style() {
      return `background-color:${this.bgColor}`;
    },
  },
  methods: {
    handleChange(file, fileList) {
      this.upload.uploadfiles = fileList;
    },
    deleteFile(val) {
      this.upload.uploadfiles.splice(val, 1);
    },
    addComments() {
      let ids = [];
      let node = document.querySelectorAll(".comment-at-span");
      [...node].map((item) => {
        ids.push(item.dataset.id);
      });
      this.input = this.$refs.divRef.innerText;
      let data = {
        ...this.d,
        uploadfiles: this.upload.uploadfiles,
        name: this.input,
        atuser: ids,
      };
      if (this.autoat) {
        data.autoat = 1;
      }
      this.$emit("addComment", data);
      this.cancel();
    },
    cancel() {
      this.input = "";
      this.users = [];
      this.upload.uploadfiles = [];
      let editor = this.$refs.divRef;
      editor.innerHTML = "";
    },
    focus(e) {
      e.target.setAttribute("default-placeholder", "");
      setTimeout(() => {
        this.$emit("update:show", true);
        this.$emit("update:eventid", this?.d?.eventid);
      }, 300);
    },
    // 失去焦点事件
    blurFun(event, res) {
      //    ......
      if (!res) {
        // 失去焦点但值为空时，还原default-placeholder为存放的placeholder属性值
        event.target.setAttribute(
          "default-placeholder",
          event.target.getAttribute("placeholder")
        );
      }
    },
    //评论内容输入事件
    contentCheng(e) {
      this.input = this.getRangeNode();
    },
    // 获取节点
    getRangeNode() {
      const selection = window.getSelection();
      return selection.focusNode; // 选择的结束节点
    },
    // 获取光标位置
    getCursorIndex() {
      const selection = window.getSelection();
      return selection.focusOffset; // 选择开始处 focusNode 的偏移量
    },
    // 是否展示 @
    showAt() {
      const node = this.getRangeNode();
      if (!node || node.nodeType !== Node.TEXT_NODE) return false;
      const content = node.textContent || "";
      const regx = /@([^@\s]*)$/;
      const match = regx.exec(content.slice(0, this.getCursorIndex()));
      return match && match.length === 2;
    },
    // 键盘抬起事件
    handkeKeyUp() {
      if (this.showAt()) {
        const node = this.getRangeNode();
        const endIndex = this.getCursorIndex();
        this.node = node;
        this.input = node;
        this.endIndex = endIndex;
        // this.position = this.getRangeRect()
        // this.queryString = this.getAtUser() || ''
        this.showMask = true;
      } else {
        this.showMask = false;
      }
      // 文本内容全部删除后显示默认文本
      if (this.$refs.divRef.innerHTML === "<br>") {
        this.input = "";
        this.$refs.divRef.innerHTML = "";
      }
    },
    //选择人员列表
    addUsers(users) {
      if (Array.isArray(users)) {
        this.users = [];
        this.users.push(...users);
        this.users = [
          ...new Set(this.users.map((el) => JSON.stringify(el))),
        ].map((el) => JSON.parse(el));
        this.replaceAtUser();
      }
      this.$nextTick(() => {
        keepLastIndex(this.$refs.divRef);
      });
    },
    //添加节点
    replaceAtUser() {
      let editor = this.$refs.divRef;
      let node = this.node;
      const endIndex = this.endIndex;
      node.textContent = this.replaceString(
        node.textContent.slice(0, endIndex),
        ""
      );
      for (let i = 0; i < this.users.length; i++) {
        const item = this.users[i];
        let spanDom = document.createElement("span");
        spanDom.classList.add("comment-at-span");
        spanDom.innerText = `@${item.name}`;
        spanDom.contentEditable = "false";
        spanDom.dataset.id = item.uid;
        editor.appendChild(spanDom);
        // 兼容火狐等浏览器，插入一个不可见的字符，获取光标
        if (!IS_CHROME) {
          const textNode = document.createTextNode("\u200b");
          editor.appendChild(textNode);
        }
      }
      this.getAtUser();
    },

    //删除第一个@字符
    replaceString(raw, replacer) {
      return raw.replace(/@([^@\s]*)$/, replacer);
    },
    //获取评论内容
    getAtUser() {
      this.input = this.$refs.divRef.innerText;
    },
  },
  watch: {
    "upload.uploadfiles": {
      handler(files) {
        if (Array.isArray(files) && files.length > 0) {
          files
            .filter((file) => !file.local_key)
            .forEach((file) => {
              uploadBigFile(file.raw, (url) => {
                file.local_key = url;
                file.origin_name = file.name;
              });
            });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .editor {
    width: 100%;
    background: #ffffff;
    border-radius: 12px;
    text-align: left;
    padding: 7px 10px;
    overflow: auto;
    line-height: 14px;
    @include lineBreak(4, auto);
    &:focus {
      outline: none;
    }
  }
  .editor::-webkit-scrollbar {
    display: none;
  }
  .editor::before {
    content: attr(default-placeholder);
    color: rgba(16, 50, 104, 0.2);
    position: absolute;
    cursor: text;
  }
  /deep/ .el-upload {
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.svg-icon {
  width: 25px !important;
  height: 25px !important;
  display: block;
}
.rightBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}
</style>
