import request from '@/utils/request'
import { REQUEST_BASEURL_OAUTH, REQUEST_BASEURL_CONFIG } from '@/constants'

/**
 * 根据职位获取权限
 */
export function getDepartmentMenuListByRole(params) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/apidepartment/`,
        method: 'get',
        params
    })
}

/**
 * 获取所有权限
*/
export function getAllPermissions() {
    return request({
        url: `${REQUEST_BASEURL_CONFIG}/apis/`,
        method: 'get',
    })
}

/**
 * 根据职位设置权限
 */
export function setDepartmentMenuListByRole(data) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/apidepartment/`,
        method: 'post',
        data
    })
}
/**
 * 获取自定义权限列表
 */
export function getPermissionsByCustomRole(params) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/apirole/`,
        method: 'get',
        params
    })
}
/**
 * 设置自定义权限
 */
export function setPermissionsByCustomRole(data) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/apirole/`,
        method: 'post',
        data
    })
}
/**
 * 创建自定义角色
 */
export function addCustomRole(data) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/role/`,
        method: 'post',
        data
    })
}
/**
 * 查询自定义角色列表
 */
export function getCustomRoleList() {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/role/`,
        method: 'get',
    })
}
/**
 * 修改角色
 */
export function updateCustomRole(data) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/role_detail/${data.id}/`,
        method: 'put',
        data
    })
}
/**
 * 删除角色
 */
export function deleteCustomRole(id) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/role_detail/${id}/`,
        method: 'delete',
    })
}
/**
 * 修改角色人员列表
 */
export function updateCustomRoleUsers(data) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/userrole/`,
        method: 'post',
        data
    })
}
/**
 * 获取角色用户列表
*/
export function getCustomRoleUsers(params) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/roleusers/`,
        method: 'get',
        params
    })
}
/**
 * 查看可见部门列表
 */
export function getReadonlyDept() {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/organization/getall_departments/`,
        method: 'post',
    })
}
/**
 * 根据部门id查看可见部门列表
 */
export function getReadonlyDeptById(id) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/departmentcansee/`,
        method: 'get',
        params: {
            who: id,
        }
    })
}
/**
 * 设置可见部门
 */
export function setReadonlyDeptById(data) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/departmentcansee/`,
        method: 'post',
        data
    })
}
/**
 * 根据token获取当前用户可操作的权限
 */
export function getIHavePermissions() {
    return request({
        url: `${REQUEST_BASEURL_CONFIG}/myapis/`,
        method: 'get',
    })
}
/**
 * 获取部门审批权限终点设置
 */
export function getApprovalEndPoint(id) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/approve/`,
        method: 'get',
        params: {
            did: id,
        }
    })
}
/**
 * 设置部门审批权限终点设置
 */
export function setApprovalEndPoint(data) {
    return request({
        url: `${REQUEST_BASEURL_OAUTH}/approve/`,
        method: 'post',
        data
    })
}