<template>
  <div class="mask">
    <div class="container">
      <div class="box" :style="bgStyle" v-infinite-scroll :class="{min:width == 380,short: width == 440, middle: width == 480, middle2: width == 800, long : width==960, max: width==1080 }">
        <div class="close" @click="closeMask">+</div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mask1",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: "#fff"
    },
    width: {
      type: Number,
      default: 600
    }
  },
  methods: {
    closeMask() {
      this.$emit("update:show", false);
    }
  },
  computed: {
    bgStyle(){
      return `background:${this.bgColor}`
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/element-form.scss";
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    position: relative;

    .box::-webkit-scrollbar {
      display: none;
    }
    .min{
      width: 380px!important;
    }
    .short{
        width: 440px!important;
    }
    .middle{
        width: 480px!important;
    }
    .middle2{
      width: 800px!important;
    }
    .long{
      width: 960px!important;
    }
    .max{
      width: 1080px!important;
    }
    .box {
      overflow: auto;
      width: 600px;
      padding: 24px 24px 40px 24px;
      box-sizing: border-box;
      max-height: 800px;
      background: #fff;
      border-radius: 24px;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */

      .close {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: #fff;
        opacity: 0.3;

        line-height: 32px;
        text-align: center;
        font-size: 36px;
        font-weight: 100;
        color: #000;
        position: absolute;
        right: 0;
        top: -40px;
        cursor: pointer;
        user-select: none;

        transform: rotate(45deg);
      }
    }
  }
}
</style>