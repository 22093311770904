import Vue from 'vue'

import '@/utils/elementUI'
import 'element-ui/lib/theme-chalk/index.css';

import '@/styles/index.scss' // global css

import App from './App.vue';
import router from './route';
import './route/permission'
import store from './store'
import axios from "axios" //npm i axios -S

import '@/icons' // icon

// import './utils/rem'
import 'amfe-flexible';
import eventHub from "@/utils/eventHub"

import MasonryWall from '@yeger/vue2-masonry-wall'
import VueCropper from 'vue-cropper'
import clickOutside from "@/directives/clickOutside"
import permission from "@/directives/permission"

Vue.use(VueCropper)
Vue.use(MasonryWall)
Vue.prototype.$eventHub = eventHub
// import waterfall from 'vue-waterfall'
// Vue.use(waterfall)

// el-button 全局防抖 标签加 v-debounce
Vue.directive('debounce', {
    inserted(el, binding) {
      el.addEventListener('click', e => {
        el.classList.add('is-disabled')
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
          el.classList.remove('is-disabled')
        }, 1000)//我这里设置的是2000毫秒也就是2秒
      })
    }
  })

Vue.directive('click-outside', clickOutside)
Vue.directive('permission', permission)
// const dashboardFlexible = require('lib-flexible-for-dashboard');
// dashboardFlexible.init(16/9)
// import infiniteScroll from "vue-infinite-scroll";
//  Vue.use(infiniteScroll);

Vue.use({axios})

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    data() {
        return {
            ws: null,
        }
    }
})