<template>
  <div class="container">
    <div class="title">任务评分</div>
    <el-table
      :data="rates"
      style="width: 100%"
      :header-cell-style="tableHeaderStyle"
      :cell-style="cellStyle"
      class="table"
    >
      <el-table-column prop="title" label="序号" width="50">
        <template slot-scope="scope">{{ scope.$index + 1}}</template>
      </el-table-column>
      <el-table-column prop="user" label="负责人" width="75"></el-table-column>
      <el-table-column prop="socreTxt" label="分数" width="280">
        <template slot-scope="scope">
          <span v-if="scope.row.score>=0">{{scope.row.score}}</span>
          <span class="grey">{{ scope.row.socreTxt }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="comment" label="评语"></el-table-column>
    </el-table>
    <el-form class="form">
      <el-form-item label="任务报告">
        <div class="report">{{ report.comment }}</div>
        <!-- <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 5}"
          v-model="report.comment"
          disabled
        ></el-input>-->
      </el-form-item>
      <el-form-item label="任务状态" v-if="report.task_obj && report.task_obj.taskstatus">
        <div class="report" :class="{'report--abnormal': report.task_abnormal}">{{ report.task_obj.taskstatus }}</div>
      </el-form-item>
      <el-form-item label="成果文件" v-if="report.uploadfiles.length>0">
        <UploadTool :files.sync="report.uploadfiles" :drag="false" :readonly="true"></UploadTool>
      </el-form-item>
      <!-- <el-form-item label="任务评分">
        <el-input class="input" v-model="form.score" type="number"></el-input>
      </el-form-item>-->
      <el-form-item label="任务评分" v-if="!notScore">
        <el-table
          :data="scores"
          style="width: 100%"
          :header-cell-style="tableHeaderStyle"
          :cell-style="cellStyle"
          class="table"
        >
          <el-table-column prop label="时效">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.punctual"
                type="number"
                placeholder="满分20"
                oninput="if(value>20)value=20"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop label="工作质量">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.quality"
                type="number"
                placeholder="满分40"
                oninput="if(value>40)value=40"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop label="态度">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.attitude"
                type="number"
                placeholder="满分20"
                oninput="if(value>20)value=20"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop label="规章">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.compliance"
                type="number"
                placeholder="满分20"
                oninput="if(value>20)value=20"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop label="总分">
            <template
              slot-scope="scope"
            >{{ +scope.row.punctual + +scope.row.quality + +scope.row.attitude + +scope.row.compliance }}</template>
          </el-table-column>
        </el-table>
      </el-form-item>

      <el-form-item label="评语">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入需求内容"
          v-model="form.comment"
          resize="none"
        ></el-input>
      </el-form-item>

      <div class="bottom">
        <div class="cancel" @click="cancel">取消</div>
        <el-button
          @click="next"
          class="submit"
          :class="{disabled: notAllow}"
          :disabled="notAllow"
          v-debounce
        >确定</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { scoreTask, getTaskReport } from "@/api/task";
import { Message } from "element-ui";
import UploadTool from "@/components/Task/uploadTool.vue";
import { getUid } from "@/utils/auth";
export default {
  components: { UploadTool },
  props: {
    noticeid: {
      type: Number,
      default: 0
    },
    task: {
      type: Number,
      default: 0
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      score: "",
      reason: "",
      scores: [
        {
          punctual: null,
          quality: null,
          attitude: null,
          compliance: null,
          score: null
        }
      ],
      form: {},
      report: {
        comment: "",
        uploadfiles: []
      }
    };
  },
  computed: {
    notAllow() {
      return !this.form.comment;
    },
    notScore() {
      if (Array.isArray(this.content.no_scores)) {
        return this.content.no_scores.flat(3).some(e => e == getUid());
      }
      return false;
    },
    rates() {
      if (this.content.rates) {
        return this.content.rates.map(e => {
          if (e.score >= 0) {
            e.socreTxt = ` (时效:${e.punctual} 质量:${e.quality} 态度:${e.attitude} 规章:${e.compliance})`;
          } else {
            e.socreTxt = "x";
          }
          return e;
        });
      } else {
        return [];
      }
    }
  },
  mounted() {
    console.log(this.task);
    getTaskReport({
      task: this.task
    })
      .then(res => {
        let arr = res.data.results;
        if (arr.length > 0) {
          let report =  arr[0];
          report.task_abnormal = report.task_obj?.taskstatus?.includes('延迟');
          this.report = report;
          console.log(this.report);
        }
      })
      .catch(err => {});
  },
  methods: {
    tableHeaderStyle(obj) {
      return "background:#FAFBFC;color:rgba(16, 50, 104, 0.6);text-align:center;border-radius:4px;border: 1px solid #fff;";
    },
    cellStyle() {
      return "text-align:center;color:#103268";
    },
    cancel() {
      this.$emit("update:show", false);
      this.form.score = "";
      this.form.comment = "";
    },
    next() {
      // if (this.form.score > 100) {
      //   Message.error("评分不能超过100");
      //   return;
      // }
      let scores = this.scores[0];
      scores.score =
        +scores.punctual +
        +scores.quality +
        +scores.attitude +
        +scores.compliance;
      if (
        !this.notScore &&
        (!scores.quality ||
          !scores.attitude ||
          !scores.compliance ||
          !scores.punctual)
      ) {
        Message.error("请完善评分");
        return;
      }
      this.form.noticeid = this.noticeid;
      scoreTask({ ...this.form, ...scores })
        .then(res => {
          Message.success("评分成功");
          this.$emit("refreshNotices");
          this.$emit("update:show", false);
          this.form.score = "";
          this.form.comment = "";
        })
        .catch(err => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.input {
  width: 120px;
}

// .el-table {
//   margin-bottom: 12px;
// }

.report {
  color: rgb(16, 50, 104);
  line-height: 18px;
  padding: 11px 0;
}

.report--abnormal{
  color: #F56C6C;
}

/deep/ .el-table .el-table__cell {
  padding: 7.5px 0 6.9px;
}

/deep/ .el-table__row > td {
  /* 去除表格线 */
  border: none;
}
/deep/ .el-table th.is-leaf {
  /* 去除上边框 */
  border: none;
}
/deep/ .el-table::before {
  /* 去除下边框 */
  height: 0;
}
/deep/ .el-switch__label.is-active {
  color: #103268;
}

/deep/ .el-table--enable-row-transition .el-table__body td.el-table__cell {
  border-bottom: 0px solid #fff;
}
/deep/ .el-table__expanded-cell {
  padding: 0;
}

/deep/ th {
  line-height: 22px;
}
/deep/ td {
  word-spacing: 6px;
}
.grey {
  opacity: 0.6;
}
</style>