import request from '@/utils/request'


//添加公告
export function addNoitce(data) {
  return request({
    url: '/api/projects/announcement/',
    method: 'post',
    data
  })
}

//修改公告
export function revampNoitce(data){
  return request({
    url: '/api/projects/announcement/'+data.id+'/',
    method: 'put',
    data
  })
}

//获取公告列表
export function getNoitceList(params) {
  return request({
    url: '/api/projects/announcement/',
    method: 'get',
    params
  })
}

//点击了解，调用任务已读接口
// /api/projects/announcement/{id}/
export function noitceRead(id){
  return request ({
    url:'/api/projects/announcement/'+id+'/',
    method:'patch'
  })
}

//获取日程管理节点
// calender
export function getCalender(data){
  return request ({
    url:'/api/projects/calender/',
    method:'post',
    data
  })
}

//变更时间申请任务提醒-点击同意
// /api/projects/taskchange/approve/
export function postApprove (data) {
  return request ({
    url:'/api/projects/taskchange/approve/',
    method:'post',
    data
  }) 
}



//任务提醒-点击同意
export function noticeApprove (data) {
  return request ({
    url:'/api/projects/notice/approve/',
    method:'post',
    data
  }) 
}

export function noticeRefuse (data) {
  return request ({
    url:'/api/projects/notice/disapprove/',
    method:'post',
    data
  }) 
}
// 删除公告
export function deleteNotice(id) {
  return request({
    url: `/api/projects/announcement/${id}/`,
    method: "delete"
  })
}
