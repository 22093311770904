<template>
  <div class="files" ref="files" :class="{readonly: readonly}">
    <VideoPlayer
      v-if="playerShow"
      :type="previewType"
      :URL="videoUrl"
      :playerShow.sync="playerShow"
    ></VideoPlayer>
    <div class="download" ref="download" v-click-outside="handleBlur">
      <div @click="clickDownlaod">下载</div>
    </div>
    <div class="file" v-for="(item,index) of filesWithUrl" :key="item.id" @click="clickDel(index)">
      <div class="inner1">
        <el-image
          v-if="item.isImg"
          class="img"
          :src="item.url"
          :data-url="item.local_url"
          :data-isimg="item.isImg"
          :data-isvideo="item.isVideo"
          :data-ispdf="item.isPDF"
          alt
        ></el-image>
        <img
          v-else
          :src="item.url"
          :data-url="item.local_url"
          :data-isimg="item.isImg"
          :data-isvideo="item.isVideo"
          :data-ispdf="item.isPDF"
        />
        <div class="fileName" :data-url="item.local_url">{{ item.origin_name }}</div>
      </div>
    </div>
    <div
      class="file"
      v-for="(item, index) of newFilesWithUrl"
      :key="item.id"
      @click="clickDel2(index)"
    >
      <div class="inner2" v-loading="item.loading">
        <img :src="item.url" alt />
        <div class="fileName">{{ item.name }}</div>
      </div>
    </div>
    <el-upload
      v-show="!readonly"
      class="upload"
      :class="{drag: drag}"
      action="https://jsonplaceholder.typicode.com/posts/"
      list-type="picture-card"
      :show-file-list="false"
      :file-list="newFiles"
      :auto-upload="false"
      :multiple="true"
      :limit="limit"
      :drag="drag"
      :on-change="handleChange"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
import { uploadBigFile } from "@/utils/bigFile";
import { getFileUrl, isImg, isVideo, isPDF } from "@/utils/task/common";
import VideoPlayer from "@/components/note/videoPlayer.vue";
export default {
  components: { VideoPlayer },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    deletedFiles: {
      type: Array,
      default: () => []
    },
    newFiles: {
      type: Array,
      default: () => []
    },
    drag: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      uf_deleted: [],
      currentLink: "",
      newPage: false,
      link: "",
      loading: false,
      playerShow: false,
      previewType: "video",
      videoUrl: ""
    };
  },
  mounted() {
    setTimeout(() => {
      let file = document.querySelectorAll(".inner1");
      file.forEach(item => {
        item.addEventListener("click", e => {
          // console.log(e.target.dataset);
          if (e.target.dataset.isimg) {
            e.stopPropagation();
            window.open(e.target.dataset.url);
            return;
          }
          if (e.target.dataset.isvideo) {
            this.videoUrl = e.target.dataset.url;
            this.playerShow = true;
            e.stopPropagation();
            return;
          }
          e.stopPropagation();
          setTimeout(() => {
            this.$refs.download.style.left = e.pageX + 20 + "px";
            this.$refs.download.style.top = e.pageY - 10 + "px";
            this.$refs.download.style.display = "block";
            this.currentLink = "";
            if (e.target.dataset.url && e.target.dataset.url.length > 0) {
              this.currentLink = e.target.dataset.url;
              this.newPage = e.target.dataset.ispdf;
              // if (this.newPage) {
              //   this.$refs.download.style.display = "none";
              // }
            }
          }, 50);
          return false;
        });
      });
    }, 200);
  },
  computed: {
    filesWithUrl() {
      return this.files.map(e => {
        e.url = getFileUrl(e);
        e.isImg = isImg(e);
        e.isVideo = isVideo(e);
        e.isPDF = isPDF(e);
        return e;
      });
    },
    newFilesWithUrl() {
      return this.newFiles.map(e => {
        e.url = getFileUrl(e);
        console.log("e...", e);
        return e;
      });
    }
  },
  methods: {
    handleChange(file, fileList) {
      this.loading = true;
      file.loading = true;
      uploadBigFile(file.raw, url => {
        file.local_key = url;
        file.origin_name = file.name;
        file.loading = false;
        this.loading = false;
        this.$emit("update:newFiles", [...fileList]);
      });
      this.$emit("update:newFiles", fileList);
      {
        setTimeout(() => {
          let file = document.querySelectorAll(".inner2");
          file.forEach(item => {
            item.addEventListener("click", e => {
              e.stopPropagation();
              return false;
            });
          });
        }, 200);
      }
    },
    clickDel(idx) {
      if (!this.readonly) {
        this.uf_deleted.push(this.files[idx].id);
        this.$emit("update:deletedFiles", this.uf_deleted);
        // this.$emit("uploadFilesDel", idx);
        this.$emit(
          "update:files",
          this.files.filter((item, index) => index !== idx)
        );
      }
    },
    clickDel2(idx) {
      this.newFiles.splice(idx, 1);
    },
    clickDownlaod() {
      if (this.newPage) {
        window.open(this.currentLink, "_blank");
      } else {
        window.location.href = this.currentLink;
      }
    },
    handleBlur() {
      this.$refs.download.style.display = "none";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/element-form.scss";

.files {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  .download {
    width: 88px;
    // height: 44px;
    border-radius: 10px;
    background: #fff;
    color: #103268;
    text-align: center;
    font-size: 14px;
    line-height: 44px;
    box-shadow: 0px 4px 25px 0px rgba(17, 22, 28, 0.2);
    cursor: pointer;
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
  }
  .file {
    width: 88px;
    position: relative;
    margin-bottom: 12px;
    .inner1,
    .inner2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .img,
    img {
      width: 56px;
      height: 56px;
      display: block;
    }
    .fileName {
      word-break: break-all;
      color: #103268;
      text-align: center;
      text-overflow: ellipsis;
      font-size: 10px;
      line-height: 12px;
    }
  }
  .file:hover::after {
    content: "+";
    width: 14px;
    height: 14px;
    line-height: 15px;
    display: block;
    position: absolute;
    text-align: center;
    border-radius: 7px;
    font-size: 12px;
    transform: rotate(45deg);
    top: 0;
    right: 12px;
    color: #fff;
    background: #103268;
    opacity: 0.4;
    cursor: pointer;
  }
  .upload {
    // margin-top: 12px;
    // margin-left: 14px;
  }
  .drag {
    width: 100%;
    margin-left: 0px;
  }
}
.readonly .file:hover::after {
  content: "";
  width: 0;
  height: 0;
}
</style>