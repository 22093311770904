<template>
  <Mask1 v-show="show" v-on="$listeners">
    <div class="addNotice">
      <div class="title">添加公告</div>
      <div class="notice_content">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <div class="textContent">
            <el-form-item prop="title" class="noticeTitle">
              <el-input
                v-model="form.title"
                maxlength="18"
                placeholder="标题(最多18个字符)"
              ></el-input>
            </el-form-item>
            <el-form-item prop="content">
              <el-input
                type="textarea"
                resize="none"
                placeholder="请输入公告内容"
                :autosize="{ minRows: 1, maxRows: 100 }"
                v-model="form.content"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="type">
              <div
                :class="[
                  upload.newFiles.length == 0 && form.uploadfiles.length == 0
                    ? 'upload-box'
                    : '',
                ]"
              >
                <UploadTool
                  :files.sync="form.uploadfiles"
                  :deleted-files.sync="upload.deletedFiles"
                  :new-files.sync="upload.newFiles"
                  :drag="
                    upload.newFiles.length == 0 && form.uploadfiles.length == 0
                  "
                ></UploadTool>
              </div>
            </el-form-item>
          </div>
          <div class="noticeTime">
            <div class="noticeTime_title">公告时间:</div>
            <el-form-item prop="duration">
              <el-radio-group v-model="form.duration" @input="selectRadio">
                <el-radio
                  :label="item.label"
                  v-for="item in visibleList"
                  :key="item.label"
                >
                  <span
                    v-if="form.share == 2 || form.share == 3"
                    @click="visible"
                    >{{ item.name }}</span
                  >
                  <span v-else>{{ item.name }}</span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="noticeTime">
            <el-form-item label="发布日期: " label-width="74px" prop="days">
              <el-date-picker
                type="date"
                placeholder
                value-format="yyyy-MM-dd"
                v-model="form.releaseTime"
                :picker-options="timeChange"
                @change="timesChanged"
              ></el-date-picker>
            </el-form-item>
          </div>
          <div class="noticeTime">
            <el-form-item>
              <el-form-item label="成员: " label-width="45px" prop="users">
                <choose-person :userlist.sync="upload.users"></choose-person>
              </el-form-item>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="bottom">
        <el-button @click="cancel" class="cancel">取消</el-button>
        <el-button
          type="primary"
          @click="addData('ruleForm')"
          class="submit"
          v-debounce
          >确定</el-button
        >
      </div>
    </div>
  </Mask1>
</template>

<script>
import Mask1 from "@/components/Task/mask.vue";
import UploadTool from "@/components/Task/uploadTool.vue";
import ChoosePerson from "@/components/Task/choosePerson.vue";
import { addNoitce } from "@/api/table";
import { Message } from "element-ui";

export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Mask1,
    UploadTool,
    ChoosePerson,
  },
  data() {
    const _this = this;
    return {
      addNoticeShow: false,
      upload: {
        deletedFiles: [],
        newFiles: [],
        users: [],
      },
      form: {
        title: "",
        content: "",
        uploadfiles: [],
        duration: "",
        releaseTime: "",
        users: [],
      },
      rules: {
        title: [
          { required: true, message: "请输入公告名称", trigger: "blur" },
          {
            min: 3,
            max: 18,
            message: "长度在 3 到 18 个字符",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            validator: function (rule, value, callback) {
              if (!value && !_this.form.uploadfiles?.length && !_this.upload.newFiles?.length) {
                callback(new Error("请输入公告内容"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        duration: [
          { required: true, message: "请选择公告时长", trigger: "change" },
        ],
      },
      visibleList: [
        {
          label: 1,
          name: "1天",
        },
        {
          label: 3,
          name: "3天",
        },
        {
          label: 7,
          name: "7天",
        },
        {
          label: 30,
          name: "30天",
        },
        {
          label: 99,
          name: "永久",
        },
      ],
    };
  },
  methods: {
    selectRadio() {},
    timeChange() {},
    timesChanged() {},
    cancel() {
      this.$emit("update:show", false);
    },
    addData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.uploadfiles = this.upload.newFiles;
          if (this.upload.users.length != 0) {
            this.form.users = this.upload.users.map((item) => {
              return item.uid;
            });
            console.log(this.form);
            this.addNoitce(this.form);
          } else {
            Message({
              message: "请选择公告查看人员",
              type: "error",
              duration: 5 * 1000,
            });
          }
        } else {
          console.log("error submit!!");
          Message({
            message: "标题、公告内容、公告时长、是必填项",
            type: "error",
            duration: 5 * 1000,
          });
          return false;
        }
      });
    },
    addNoitce(data) {
      addNoitce(data)
        .then((res) => {
          Message({
            message: "公告发布成功！",
            type: "success",
            duration: 5 * 1000,
          });
          this.cancel();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // content: ""
    // duration: ""
    // releaseTime: ""
    // t: 10
    // title: ""
    // uploadfiles: []
    // users: []
  },
};
</script>

<style lang="scss" scoped>
.container {
  .notice_content {
    max-height: 552px;
    overflow: auto;

    .textContent {
      background: rgba(244, 249, 255, 1);
      // height:272px;
      border-radius: 12px;
      padding: 16px;

      .noticeTitle {
        border-bottom: 1px solid rgba(24, 110, 255, 0.05);
      }

      // background:#F4F9FF ;
      // padding:16px 16px 16px;
      // border-radius: 12px;
      ::v-deep .el-input__inner {
        background: none;
        // border: 1px solid #000;
        height: 17px;
        padding: 10px 0 18px;
        border-radius: 0;
        font-size: 15px;
        font-family: HarmonyOS_Sans_SC;
        font-weight: 600;
        width: 400px;
      }

      ::v-deep .el-input__inner::placeholder {
        color: rgba(16, 50, 104, 0.25);
        font-weight: bold;
      }

      //文本域 textarea
      ::v-deep .el-textarea__inner {
        background: none;
        // border: 1px solid #000;
        border-radius: 0;
        font-family: HarmonyOS_Sans_SC;
        padding: 0;
        margin-top: 12px;
        font-size: 14px;
        height: 123px !important;
        overflow: auto;
      }

      ::v-deep .el-textarea__inner::placeholder {
        color: rgba(16, 50, 104, 1);
        // color: red;
      }

      ::v-deep .el-form-item__content {
        line-height: 0;
      }

      .el-form-item {
        margin: 0;
      }

      ::v-deep .el-upload-dragger {
        background: none !important;
      }
    }

    .noticeTime {
      margin-top: 24px;

      .noticeTime_title {
        font-size: 14px;
        font-family: HarmonyOS_Sans_SC;
        color: rgba(16, 50, 104, 0.6);
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 8px;
      }
    }
  }

  .notice_content::-webkit-scrollbar {
    width: 0;
  }
}

//标题-input

// ::v-deep .el-upload--picture-card{
//     background: none;
//     // margin-left: 12px;
// }
// ::v-deep .el-upload-list__item-actions{
//     border: 1px solid #000;
// }

::v-deep .el-textarea__inner::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  /* 设置滚动条宽度 */
  height: 6px;
}

/* 滑道部分 */
::v-deep .el-textarea__inner::-webkit-scrollbar-track {
  background: none;
  border-radius: 0;
}

/* 滑块部分 */
::v-deep .el-textarea__inner::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: #d5d9de;
  transition: color 0.2s ease;
}

/* 当悬停时显示的效果 */
::v-deep .el-textarea__inner::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

// ::v-deep .el-upload-list--picture-card .el-upload-list__item{
//     width: 72px;
//     height:100%;
//     border: none;
//     border-radius: 0px;
//     line-height: 0;
//     background: none;
//     position: relative;
//     // border: 1px dashed red !important;
// }
// .el-upload-list--picture-card .el-upload-list__item-thumbnail {
//     width:40px;
//     height:40px;
//     // border: 1px solid #c0ccda;
//     border-radius: 6px;
//     margin-top: 8px;
//     margin-left: 8px;
// }
// ::v-deep .img-uploader p { /* 文件名过长后显示省略号 */
//     width: 56px;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;
//     // border: 1px solid #000;
// }
// .fileName{
//     width: 40px;
//     font-size: 10px;
//     // word-wrap: break-word; /* 允许在单词内换行 */
//     // overflow-wrap: break-word; /* 确保兼容性 */
//     line-height:15px;
//     margin-left: 8px;
//     // border: 1px solid #000;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     margin-block-start: 0px !important;
//     // margin-block-end: 0 !important;
//     // margin-inline-start: 0 !important;
//     // margin-inline-end: 0 !important;
// }
// .FilesDelete{
//     position: absolute;
//     top: 2px;
//     right:8px;
//     .svg-icon{
//         width: 12px;
//         height: 12px;
//     }
// }
// ::v-deep .el-upload-list--picture-card .el-upload-list__item {
//     margin: 0 8px 0 0;
// }
// //预览
// ::v-deep .avatarImg .el-dialog__header {
//     padding: 20px;
// }

// 单选按钮部分
// ::v-deep .el-radio__inner{
//   width: 20px;
//   height: 20px;
// //   background-color: #2D68FE;
// //   border: 2px solid #2D68FE;
// }
::v-deep .el-radio__input.is-checked {
  border: 2px solid #0d68fd;
  border-radius: 50%;
  line-height: 0.5px;
  padding: 2px;
}

// ::v-deep .el-radio__inner::after{
//   background-color: none;
// }
// ::v-deep .el-radio__input.is-checked .el-radio__inner{
//   border:4px solid #fff;
//   width: 20px;
//   height: 20px;;/
//   background-color: #2D68FE;
// }
/**
  修改el-radio的选中样式
*/
/deep/ {
  .el-radio {
    margin-right: 0;
    padding-right: 32px;
  }

  .el-radio:nth-child(3),
  .el-radio:nth-child(4) {
    padding-right: 48px;
  }

  .el-radio__inner {
    width: 16px;
    height: 16px;
  }

  .el-radio__input.is-checked .el-radio__inner {
    width: 11px;
    height: 11px;
    background: #0d68fd;
  }

  .el-radio__input.is-checked .el-radio__inner::after {
    // content: '';
    width: 8px;
    height: 4px;
    // border: 2px solid white;
    border-top: transparent;
    border-right: transparent;
    text-align: center;
    display: block;
    position: absolute;
    // // top: 4px;
    // // left: 3px;
    // // vertical-align: middle;
    // // transform: rotate(-45deg);
    border-radius: 0;
    background: none;
  }

  .el-radio__label {
    color: #103268;
    font-family: HamonyOS_Sans_SC;
    font-weight: 500;
    font-size: 14px;
    // display: flex;
  }
}
</style>
