import Vue from 'vue'

import {
    Button,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Breadcrumb,
    BreadcrumbItem,
    Scrollbar, //隐藏组件
    Avatar, //头像
    Tag,//标签

    Calendar,//日历组件
    Badge,//标记
    DatePicker,//日期选择
    Input,//输入框
    Checkbox,//复选框
    Progress,//进度条

    Popover,
    InfiniteScroll,
    Form,
    FormItem,
    Upload,
    CheckboxGroup,
    Radio,
    RadioGroup,
    Switch,
    Loading,
    Table,
    TableColumn,
    Select,
    Option,
    Image,
    Message,
    MessageBox,
    Dialog,
    Tree,
    Tooltip,
    Drawer,
    Tabs,
    TabPane,
    Row,
    Col,
    Divider,
    InputNumber
} from 'element-ui'

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

Vue.use(Button)

Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Scrollbar)
Vue.use(Avatar)
Vue.use(Tag)
Vue.use(Calendar)
Vue.use(Badge)
Vue.use(DatePicker);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Progress)

Vue.use(Popover)
Vue.use(InfiniteScroll)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Upload)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Switch)
Vue.use(Loading)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Image)
Vue.use(Dialog)
Vue.use(Tree)
Vue.use(Tooltip)
Vue.use(Drawer)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Row)
Vue.use(Col)
Vue.use(Divider)
Vue.use(InputNumber)