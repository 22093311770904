import Layout from '@/views/layout/Layout.vue';
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 解决动态路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

// 自定义布局页面布局
export const constantRoutes = [
    { 
        path: '/login', 
        component: () => import('@/views/login/login-user'), 
        hidden: true 
    },
    { 
        path: '/login-admin', 
        component: () => import('@/views/login'), 
        hidden: true 
    },
    { 
        path: '/projectList', 
        component: () => import('@/views/projectList'), 
        hidden: true 
    },
    { 
        path: '/create-company', 
        component: () => import('@/views/peopleManagement/architecture.vue'), 
        hidden: true 
    },
    {
      path: "/setting",
      component: Layout,
      redirect: "/setting/userInfo",
      hidden: true,
      children: [
        {
          path: "userInfo",
          name: "pm-userInfo",
          meta: {
            title: '个人信息',
            icon: 'pm-icon-zuzhijiagou',
          },
          component: () => import('@/views/user/index'),
          hidden: true
        },
        {
          path: "architecture",
          name: "pm-architecture",
          meta: {
            title: '组织架构',
            icon: 'pm-icon-zuzhijiagou',
          },
          component: () => import('@/views/peopleManagement/architecture.vue'),
          hidden: true
        },
        {
          path: "permissionRoles",
          name: "pm-permissionRoles",
          component: () => import('@/views/settings/permissionRoles/index'),
          hidden: true,
          meta: {
            title: '自定义角色权限设置',
            icon: 'pm-icon-jiaosequanxian',
          },
        },
        {
          path: "approvalAuthority",
          name: "pm-approvalAuthority",
          component: () => import('@/views/settings/approvalAuthority/index'),
          hidden: true,
          meta: {
            title: '审批权限终点设置',
            icon: 'pm-icon-a-16_TY_C_NV_shezhijiaosequanxian',
          },
        }
      ]
    },
    // {
    //     path: '/',
    //     component: Layout,
    //     redirect: '/index',
    //     name: 'Product',
    //     meta: { title: '产品研发', icon:'product_icon', affix:true},
    //     children: [
    //         {
    //             path: 'index',
    //             name: 'Index',
    //             component: () => import('@/views/index'),
    //             meta: { title: '我的任务', icon: '', count:3}
    //         },
    //         {
    //             path: 'projectProgress',
    //             name: 'ProjectProgress',
    //             component: () => import('@/views/projectProgress'),
    //             meta: { title: '项目进度', icon: '',count:9}
    //         },
    //         {
    //             path: 'caiGouRenWu',
    //             name: 'CaiGouRenWu',
    //             component: () => import('@/views/index'),
    //             meta: { title: '采购任务', icon: '',count:9}
    //         },
    //         {
    //             path: 'waiFaYeWau',
    //             name: 'WaiFaYeWu',
    //             component: () => import('@/views/index'),
    //             meta: { title: '外发任务', icon: '',count:3}
    //         },
    //         {
    //             path: 'supplierForm',
    //             name: 'supplierForm',
    //             component: () => import('@/views/index'),
    //             meta: { title: '供应商信息', icon: '',count:2}
    //         },
    //         {
    //             path: 'xiangMuChengYuan',
    //             name: 'XiangMuChengYuan',
    //             component: () => import('@/views/index'),
    //             meta: { title: '项目成员', icon: '',count:16}
    //         },
    //         {
    //             path: 'yuYanJiHua',
    //             name: 'YuYanJiHua',
    //             component: () => import('@/views/index'),
    //             meta: { title: '预研计划', icon: '',}
    //         },
    //     ]
    // },
    // {
    //     path: '/decorativeDesign',
    //     component: Layout,
    //     redirect: '/decorativeDesign/projectschedule',
    //     name: 'decorativeDesign',
    //     meta: { title: '装饰设计', icon:'design_icon' },
    //     children: [
    //         {
    //             path: 'projectschedule',
    //             name: 'projectschedule',
    //             component: () => import('@/views/index'),
    //             meta: { title: '项目进度', icon: '', affix:true}
    //         },
    //         {
    //             path: 'yeWuGenZong',
    //             name: 'YeWuGenZong',
    //             component: () => import('@/views/index'),
    //             meta: { title: '业务跟踪', icon: '', affix:true}
    //         },
    //         {
    //             path: 'project_member',
    //             name: 'Project_member',
    //             component: () => import('@/views/index'),
    //             meta: { title: '项目成员', icon: '', affix:true}
    //         },
    //         {
    //             path: 'Outgoing_business',
    //             name: 'Outgoing_business',
    //             component: () => import('@/views/index'),
    //             meta: { title: '外发业务', icon: '', affix:true}
    //         },
    //         {
    //             path: 'collection_summary',
    //             name: 'Collection_summary',
    //             component: () => import('@/views/index'),
    //             meta: { title: '收款汇总', icon: '', affix:true}
    //         },
    //     ]
    // },
    // {
    //     path: '/otherWork',
    //     component: Layout,
    //     redirect: '/otherWork/projectschedule',
    //     name: 'OtherWork',
    //     meta: { title: '其他工作', icon:'otherWork_icon' },
    //     children: [
    //         {
    //             path: 'projectschedule',
    //             name: 'projectschedule',
    //             component: () => import('@/views/index'),
    //             meta: { title: '项目进度', icon: '', affix:true}
    //         },
    //         {
    //             path: 'Outgoing_business',
    //             name: 'Outgoing_business',
    //             component: () => import('@/views/index'),
    //             meta: { title: '外发业务', icon: '', affix:true}
    //         },
    //         {
    //             path: 'project_member',
    //             name: 'Project_member',
    //             component: () => import('@/views/index'),
    //             meta: { title: '项目成员', icon: '', affix:true}
    //         },
    //     ]
    // },
    // { 
    //     path: '/itemRecord',
    //     component: Layout,
    //     redirect: '/itemRecord/projectschedule',
    //     name: 'ItemRecord',
    //     meta: { title: '项目记录', icon:'itemRecord_icon' },
    //     children: [
    //         {
    //             path: 'projectschedule',
    //             name: 'projectschedule',
    //             component: () => import('@/views/index'),
    //             meta: { title: '项目归档', icon: '', affix:true}
    //         },
    //         {
    //             path: 'Outgoing_business',
    //             name: 'Outgoing_business',
    //             component: () => import('@/views/index'),
    //             meta: { title: '项目笔记', icon: '', affix:true}
    //         },
    //     ]
    // },
    // {
    //     path: '/peopleManagement',
    //     component: Layout,
    //     redirect: '/peopleManagement/projectschedule',
    //     name: 'PeopleManagement',
    //     meta: { title: '人员管理', icon:'staff_icon' },
    //     children: [
    //         {
    //             path: 'projectschedule',
    //             name: 'projectschedule',
    //             component: () => import('@/views/index'),
    //             meta: { title: '组织架构', icon: '', affix:true}
    //         },
    //         {
    //             path: 'infos',
    //             name: 'StaffInfos',
    //             component: () => import('@/views/peopleManagement/infos'),
    //             meta: { title: '人员信息', icon: '', affix:true}
    //         },
    //         {
    //             path: 'info',
    //             name: 'StaffInfo',
    //             component: () => import('@/views/peopleManagement/info'),
    //             meta: { title: '人员信息详情', icon: '', affix:true},
    //             hidden: true
    //         },
    //         {
    //             path: 'Outgoing_business',
    //             name: 'Outgoing_business',
    //             component: () => import('@/views/index'),
    //             meta: { title: '人员绩效', icon: '', affix:true}
    //         },
    //         {
    //             path: 'setAuthorization',
    //             name: 'setAuthorization',
    //             component: () => import('@/views/setAuthorization'),
    //             meta: { title: '权限设置   ', icon: '', affix:true}
    //         },
    //     ]
    // },
    // {
    //     path: '/homePages',
    //     component: Layout,
    //     redirect: '/homePages/projectschedule',
    //     name: 'HomePages',
    //     meta: { title: '我的主页', icon:'homePage_icon' },
    //     children: [
    //         {
    //             path: 'projectschedule',
    //             name: 'projectschedule',
    //             component: () => import('@/views/index'),
    //             meta: { title: '我的主页', icon: '', affix:true}
    //         },
    //         {
    //             path: 'userInfo',
    //             name: 'UserInfo',
    //             component: () => import('@/views/user'),
    //             meta: { title: '个人信息', icon: '', affix:true}
    //         },
    //     ]
    // },
]
// 创建路由
const createRouter = () => new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

//重置路由
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
