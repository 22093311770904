<template>
  <div class="empty-container">
    <router-view></router-view>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  mounted() {
    console.log('empty layout...');
  },
  methods: {}
};
</script>
  
<style lang="scss" scoped>
.empty-container {
    width: 100%;
    height: 100%;
    // background: red;
}
</style>
  