<template>
    <div class="mask">
        <div class="container">
            <div class="video" v-if="type == 'video'">
              <video width="1100" height="645" controls>
                <source :src="URL">
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="pic" v-else>
                <img :src="URL" alt="">
            </div>
            <div class="close" @click="closeMask">+</div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "mask1",
    props: {
      show: {
        type: Boolean,
        default: false
      },
      type: {
          type: String,
          default: 'video'
      },
      URL:{
        type: String,
      }
    },
    data() {
        return {
            // url:'https://dc.yichenghbkj.com:18001//uploads/24-03-22/测试视频.mp4',
            // // url: 'https://qiniu-web-assets.dcloud.net.cn/unidoc/zh/2minute-demo.mp4',
            // poster: 'https://cdn.jsdelivr.net/gh/themusecatcher/resources@0.0.3/bao.jpg'
        }
    },
    mounted() {
      console.log('文件地址',this.URL);
    },
    methods: {
      closeMask() {
        this.$emit('update:playerShow', false);
        this.$emit('closeMask');
      }
    },
    computed: {
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import "../../styles/element-form.scss";
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  
    display: flex;
    justify-content: center;
    align-items: center;
    .container{
        position: relative;
        .close {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: #fff;
        opacity: 0.3;

        line-height: 32px;
        text-align: center;
        font-size: 36px;
        font-weight: 100;
        color: #000;
        position: absolute;
        right: -30px;
        top: -40px;
        cursor: pointer;
        user-select: none;

        transform: rotate(45deg);
      }
    }
  }
  </style>