<template>
  <div class="container">
    <div class="content">
      您确定要将“
      <span class="title">{{name}}</span> ”{{title}}？
    </div>
    <div class="bottom">
      <div class="cancel" @click="cancel">取消</div>
      <div class="submit" @click="confirm" v-debounce>删除</div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    name:{
      type:String,
    },
    title:{
      type:String,
    }
  },
  methods: {
    //点击取消按钮
    cancel(){
      this.$emit('cancel')
      this.$emit("update:show",false)
    },
    confirm(){
      this.$emit("update:show", false);
      this.$emit('confirm')
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  .content {
    color: #103268;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    margin-top: 24px;

    .title {
      color: #0d68fd;
    }
  }
  .submit {
    background: linear-gradient(56deg, #f56956 16.47%, #ff8a7a 83.18%);
  }
}
</style>