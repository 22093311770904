<template>
  <Mask1 :width="640" v-if="show" v-on="$listeners">
    <div class="report-container">
      <div class="title">任务报告</div>
      <el-form class="form" :model="form" :rules="rules" ref="form">
        <el-form-item label="任务报告" prop="comment">
          <el-input
            class="input"
            type="textarea"
            placeholder="请输入任务报告"
            :autosize="{ minRows: 2, maxRows: 5}"
            v-model="form.comment"
          ></el-input>
        </el-form-item>
        <el-form-item label="成果文件">
          <div class="reqiure-box">
            <div class="upload-box">
              <UploadTool :new-files.sync="form.uploadfiles"></UploadTool>
            </div>
          </div>
        </el-form-item>
        <div class="bottom">
          <div class="cancel" @click="cancel">取消</div>
          <el-button class="submit" @click="submit" v-debounce>确定</el-button>
        </div>
      </el-form>
    </div>
  </Mask1>
</template>
  
  <script>
import Mask1 from "@/components/Task/mask.vue";
import UploadTool from "@/components/Task/uploadTool.vue";
import { addReport } from "@/api/task";
import { Message } from "element-ui";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    task: {
      type: Number,
      default: 0
    }
  },
  components: {
    Mask1,
    UploadTool
  },
  data() {
    return {
      form: {
        comment: "",
        uploadfiles: []
      },
      rules: {
        comment: [
          { required: true, message: "请输入任务报告", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {},
  methods: {
    cancel() {
      this.$emit("update:show", false);
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.task = this.task;
          addReport(this.form)
            .then(res => {
                this.$emit("reportSuc", null);
                Message.success("任务报告提交成功");
            })
            .catch(err => {});
        }
      });
    }
  }
};
</script>
  
<style scoped lang="scss">
.reqiure-box {
  padding-top: 12px;
}
</style>