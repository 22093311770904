<template>
  <div class="notice-history-list-wrapper" v-loading="showLoading">
    <header>
      <el-date-picker
        v-model="selectedDate"
        type="month"
        placeholder="选择日期"
        size="mini"
        @change="handleDateChange"
      >
      </el-date-picker>
      <div class="static-date-picker">
        <span
          :class="{ active: searchMine === 'mine' }"
          @click="handleSelectFilter('mine')"
          >我的</span
        >
        <span
          :class="{ active: quickFilter === 1 }"
          @click="handleSelectFilter(1)"
          >近一月</span
        >
        <span
          :class="{ active: quickFilter === 3 }"
          @click="handleSelectFilter(3)"
          >近三月</span
        >
      </div>
    </header>
    <TransitionGroup name="data">
      <div class="list" v-for="(item, index) in historyList" :key="item.id">
        <div class="list_title">
          <div v-if="!item.has_read" class="unread-tips"></div>
          <div class="list_title-top">
            <div class="list_title_left">
              <div class="creator-box" v-if="item.user_data">
                <img :src="item.user_data.headimg" :draggable="false" alt="" />
                <span>{{ item.user_data.name }}</span>
                <span class="time">{{ formatTime(item.releaseTime) }}</span>
              </div>
            </div>
            <div class="list_title_right">
              <div
                v-permission="'DELETE_ANNOUNCEMENT'"
                class="btn"
                @click="handleDelete(item)"
              >
                删除
              </div>
            </div>
          </div>
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="content" style="color: #f7e6c8">
          <span>{{ item.content }}</span>
          <displayFileType :Files.sync="item.uploadfiles" :Type="2" />
          <div
            class="icon-open"
            v-if="item.unreads.length > 0"
            :class="{ 'icon-close': item.showUnread }"
            @click="handleUnreads(index)"
          ></div>
        </div>
        <div v-show="item.showUnread" class="unread-title">未读人员</div>
        <div v-show="item.showUnread" class="unreads">
          <div class="user" v-for="i of item.unreads" :key="i.uid">
            <img class="user-headimg" :src="i.headimg" alt />
            <div class="user-name">{{ i.name }}</div>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import displayFileType from "@/components/displayFileType.vue";
import { getNoitceList, deleteNotice } from "@/api/table";
import dayjs from "dayjs";

export default {
  components: {
    displayFileType,
  },
  data() {
    return {
      selectedDate: "",
      historyList: [],
      showLoading: false,
      quickFilter: null,
      searchMine: null,
      formatTime: (time) => dayjs(time).format("YYYY-MM-DD")
    };
  },
  methods: {
    async handleDelete(item) {
      try {
        await this.$confirm("确定删除这条公告吗？", "提示", {
          type: "warning",
        });
        await deleteNotice(item.id);
        this.$message.success("删除成功");
        this.queryHistoryList();
      } catch (err) {}
    },
    async queryHistoryList(date = null) {
      this.showLoading = true;
      try {
        const params = {
          date: "",
          my_notice: this.searchMine ? 1 : 0,
          ishistory: 1,
        };
        if (this.selectedDate || date) {
          const targetDate = dayjs(this.selectedDate).format("YYYY-MM");
          params.date = date || targetDate;
        }
        const response = await getNoitceList(params);
        this.historyList = response.data?.results || [];
      } catch (err) {
        this.historyList = [];
      } finally {
        this.showLoading = false;
      }
    },
    handleSelectFilter(payload) {
      let paramDate = "";
      if (payload === this.searchMine) {
        this.searchMine = null;
      } else if (payload === this.quickFilter) {
        this.quickFilter = null;
      } else if (payload === "mine") {
        this.searchMine = "mine";
      } else {
        this.quickFilter = payload;
        this.selectedDate = "";
        if (payload === 1) {
          paramDate = dayjs().subtract(1, "month").format("YYYY-MM-DD");
        } else {
          paramDate = dayjs().subtract(3, "month").format("YYYY-MM-DD");
        }
      }
      this.queryHistoryList(paramDate);
    },
    handleDateChange() {
      this.quickFilter = null;
    },
    handleUnreads(idx) {
      if (idx < this.historyList.length) {
        this.historyList[idx].showUnread = !this.historyList[idx].showUnread;
        this.$set(this.historyList, idx, this.historyList[idx]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-move,
/* 对移动中的元素应用的过渡 */
.data-enter-active,
.data-leave-active {
  transition: all 0.5s ease;
}

.data-enter-from,
.data-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* 确保将离开的元素从布局流中删除
    以便能够正确地计算移动的动画。 */
.data-leave-active {
  position: absolute;
}

.unread-title {
  margin: 12px 0;
  color: #fff;
  font-size: 12px;
  // border-top: 0.00521rem solid #7d7d7d;
  padding-top: 12px;
}

.unreads {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 12px;

  .user {
    margin-right: 8px;
    margin-bottom: 8px;
    min-width: vw(38);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-headimg {
      width: 20px;
      height: 20px;
      margin-bottom: 6px;
      border-radius: 50%;
    }

    &-name {
      font-size: 12px;
      color: #fff;
    }
  }
}
.notice-history-list-wrapper {
  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 12px 0;
    .el-date-editor {
      width: 150px;
    }

    .static-date-picker {
      display: flex;
      column-gap: 2px;
      flex-shrink: 0;

      span {
        display: inline-block;
        padding: 2px 10px;
        border-radius: 4px;
        color: var(--pm-main-menu-active-color);
        font-size: 12px;

        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }
      }

      span.active {
        background-color: #ecf4ff;
      }
    }
  }

  .list {
    border-radius: 12px;
    background: #3b352c;
    padding: vh(16);
    margin: vh(8) 0;
    position: relative;
    &_title {
      width: 100%;
      border-bottom: 1px solid #7d7d7d;
      padding-bottom: 5px;
      .creator-box {
        display: flex;
        align-items: center;
        column-gap: 6px;
        height: 26px;
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        .time {
          font-size: 12px;
        }
      }
      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .title {
        width: 100%;
        font-size: 14px;
        color: #faedd6;
        word-break: break-all;
      }

      &_left {
        font-size: vh(16);
        color: #faedd6;
        font-family: 800;
        width: 100%;

        .text {
          margin-top: vh(8);
          font-size: vh(13);
        }
      }

      &_right {
        color: #000;
        font-size: vh(10);
        text-align: center;
        display: flex;
        align-items: center;
        .count {
          font-size: 14px;
          color: #f7e6c8;
          margin-right: 5px;
          white-space: nowrap;
        }
        .btn {
          @include cursorPointer;
          width: vw(48);
          height: vh(24);
          line-height: vh(24);
          border-radius: 50px;
          background: linear-gradient(56deg, #f04c4c 16.47%, #ef0707 83.18%);
        }
      }
    }

    .content {
      margin-top: 5px;
      font-weight: 700;
      font-size: 12px;
      position: relative;

      .icon-close {
        transform: rotate(180deg) translateY(-4px);
      }

      .icon-open {
        width: 22px;
        height: 22px;
        position: absolute;
        right: 0px;
        bottom: -4px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include cursorPointer;
        transition: all 0.3s ease-in-out;

        &::after {
          content: "";
          display: block;
          width: 9px;
          height: 9px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(45deg);
        }
      }

      span {
        padding-bottom: 10px;
      }
    }
  }
}
</style>
