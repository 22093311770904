import pdf from "@/assets/file/pdf.png";
import excel from "@/assets/file/excel.png";
import ppt from "@/assets/file/ppt.png";
import link from "@/assets/file/link.png";
import unknown from "@/assets/file/unknown.png";
import word from "@/assets/file/word.png";
import zip from "@/assets/file/zip.png";

export function addFileName(files) {
  setTimeout(() => {
    if (Array.isArray(files)) {
      let list = document.getElementsByClassName(
        "el-upload-list__item"
      );
      files.forEach((item, index) => {
        list[index].setAttribute("data-name", item.id > 0 ? item.origin_name : item.name);
      })
    }
  }, 300);
}

export function addFileNameWithDom(files, dom) {
  setTimeout(() => {
    let childNodes = [...dom?.$el.getElementsByClassName("el-upload-list__item")];
    // console.log('addFileNameWithDom', childNodes, typeof childNodes,Array.isArray(childNodes));
    if (Array.isArray(childNodes)) {
      files.forEach((item, index) => {
        childNodes[index].setAttribute("data-name", item.id > 0 ? item.origin_name : item.name);
      })
    }
  }, 300)

}

export function getFileUrl(file) {
  file.name = file?.id > 0 ? file.origin_name : file.name;
  if (/\.(jpg|jpeg|gif|png|GIF|JPG|PNG)$/.test(file.name)) {
    return file.local_url ? file.local_url : file.url;
  } else if (/\.(pdf)$/.test(file.name)) {
    return pdf;
  } else if (/\.(ppt)$/.test(file.name)) {
    return ppt;
  } else if (/\.(xlsx|xls)$/.test(file.name)) {
    return excel;
  } else if (/\.(word|doc|docx)$/.test(file.name)) {
    return word;
  } else if (/\.(js|java|vue|html|css)$/.test(file.name)) {
    return link;
  } else if (/\.(zip|rar)$/.test(file.name)) {
    return zip;
  } else {
    return unknown;
  }
}

export function isImg(file) {
  file.name = file?.id > 0 ? file.origin_name : file.name;
  if (/\.(jpg|jpeg|gif|png|GIF|JPG|PNG)$/.test(file.name)) {
    return true;
  } else {
    return false;
  }
}

export function isVideo(file) {
  file.name = file?.id > 0 ? file.origin_name : file.name;
  if (/\.(mp4)$/.test(file.name)) {
    return true;
  } else {
    return false;
  }
}

export function isPDF(file) {
  file.name = file?.id > 0 ? file.origin_name : file.name;
  if (/\.(pdf)$/.test(file.name)) {
    return true;
  } else {
    return false;
  }
}