<template>
  <div class="calendar">
    <div class="event-box" v-click-outside="() => { showEventBox = false }">
      <transition name="fade" mode="out-in">
        <EventList v-if="showEventBox" :eventid="eventid" :show-reply="true"></EventList>
      </transition>
    </div>
    <div class="top" @click="closeSchedule">
      <div class="title">日程安排</div>
      <div class="icon" @click="openCalendar(false)" v-if="open">
        <img src="../../../../assets/taskReminder_Moer-icon.png" alt />
      </div>
      <div class="icon" @click="openCalendar(true)" v-else>
        <img src="../../../../assets/notice-top_icon.png" alt />
      </div>
    </div>
    <div class="checkSchedule" :class="{ showSchedule: scheduleShow }" v-click-outside="() => { scheduleShow = false }">
      <div class="tip-title">事件对话</div>
      <div class="event" v-for="(item, idx) in scheduleList" :key="item.id">
        <div>{{ item.projectname }}</div>
        <div class="task" @click="toProjectList(item)"> 
          <img v-for="user of item.users" :key="user.uid" :src="user.headimg" :title="user.name" /> 
          {{ item.taskname }}
        </div>
        <el-tooltip :content="`${idx + 1}. ${item.content}`" :open-delay="300">
          <span class="content" @click.stop="showEventList(item.id)">{{ `${idx + 1}. ${item.content}` }}</span>
        </el-tooltip>
      </div>
    </div>

    <div class="data-analysis" :class="{ close: !open }">
      <div class="header" @click="closeSchedule">
        <div class="header_left" @click="clickLastMonth"></div>
        <div class="header_title">{{ params.date }}</div>
        <div class="header_left header_right" @click="clickNextMonth"></div>
      </div>
      <el-calendar v-model="calendarValue1" ref="domCalendarBtn">
        <template slot="dateCell" slot-scope="{date, data}">
          <div>
            <div v-for="(item, key) in activeday" :key="key">
              <div v-if="data.day == item.date" :class="[data.day == item.date ? 'activeday ' : '']"
                @click.stop="getday($event, item.data)">{{ data.day.split('-').slice(2).join('-') }}</div>
              <!-- <el-badge v-if="data.day == item.dat" is-dot class="item"></el-badge>
              :class="[data.day == item.dat ? 'activeday ' : '']"-->
            </div>
            <div class="spandate">{{ data.day.split('-').slice(2).join('-') }}</div>
          </div>
        </template>
      </el-calendar>
    </div>
  </div>
</template>

<script>
import { getCalender } from "../../../../api/table";
import EventList from "@/components/Task/eventList.vue";

export default {
  components: { EventList },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 日历
      calendarValue1: new Date(),
      activeday: [
        // 日历组件选中的日期，小红点，时间格式必须为yyyy-MM-dd，比如3月5号要写成03-05而不是3-5
        { dat: "2023-12-05" },
        { dat: "2023-12-12" },
        { dat: "2023-12-21" },
        { dat: "2023-12-26" }
      ],
      params: {
        date: null
      },
      clientLeft: 0,
      clientTop: 0,
      scheduleShow: false,
      date: {
        year: null,
        month: null
      },
      scheduleList: [],
      atuser: [],
      id: null,
      placeholder: "添加评论并@成员",
      input: null,
      users: [],
      node: "",
      endIndex: null,
      showEventBox: false,
      eventid: null
    };
  },

  created() {
    this.params.date = this.onFilterDate();
    this.splitString(this.params.date);
  },

  methods: {
    showEventList(id) {
      this.showEventBox = true;
      this.eventid = id;
    },
    openCalendar(v) {
      this.$emit("update:open", v);
    },
    toProjectList(obj) {
      const { taskid, projectid } = obj;
      const path = taskid
        ? `/projectList?id=${projectid}&taskid=${taskid}`
        : `/projectList?id=${projectid}`;
      const { href } = this.$router.resolve({
        path
      });
      window.open(href, "_blank");
      this.scheduleShow = false;
    },
    // 失去焦点事件
    blurFun(event, res) {
      //    ......
      if (!res) {
        console.log("值为空时");
        // 失去焦点但值为空时，还原default-placeholder为存放的placeholder属性值
        event.target.setAttribute(
          "default-placeholder",
          event.target.getAttribute("placeholder")
        );
      } else {
        console.log("值不为空时");
      }
    },

    // 获取当前日期
    onFilterDate() {
      let myDate = this.calendarValue1;
      let y = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
      let m = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      m = m > 9 ? m : `0${m}`;
      //   let d = myDate.getDate();        //获取当前日(1-31)
      //   d = d > 9 ? d : `0${d}`;
      return `${y}.${m}`;
    },
    //分割年月
    splitString(val) {
      this.date.year = val.split(".")[0];
      this.date.month = val.split(".")[1];
      this.getCalender();
    },
    //处理时间
    handleTime(s) {
      return s < 10 ? "0" + s : s;
    },
    //点击上个月
    clickLastMonth() {
      this.$refs.domCalendarBtn.$children[0].$children[0].$el.click();
      console.log("点击上个月" + this.calendarValue1);
      //重新获取日历的标题(2023.12)
      this.params.date = this.onFilterDate();
      this.splitString(this.params.date);
    },
    // 点击下个月
    clickNextMonth() {
      this.$refs.domCalendarBtn.$children[0].$children[2].$el.click();
      // 如果你要触发原来的那个今天按钮就是
      // this.$refs.domCalendarBtn.$children[0].$children[1].$el.click()
      //重新获取日历的标题(2023.12)
      this.params.date = this.onFilterDate();
      this.splitString(this.params.date);
    },
    getday(e, data) {
      console.log(e);
      this.scheduleShow = true;
      this.clientLeft = e.x + "px";

      console.log(e.view.innerWidth);

      let innerWidth = e.view.innerWidth;
      if (e.view.innerWidth - e.x < 100) {
        this.clientLeft = innerWidth - 90 + "px";
      }

      this.clientTop = e.y + "px";
      console.log(data);
      this.scheduleList = [...data];
    },
    closeSchedule() {
      this.scheduleShow = false;
    },
    //获取日程节点
    getCalender() {
      // console.log(this.params.date)
      getCalender(this.date)
        .then(res => {
          this.activeday = res.data;
          console.log(this.activeday);
        })
        .catch(err => {});
    },

  }
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}

.calendar {
  // margin-top: 2.48%;
  // height: vh(304);
  // height: 304px;
  background: #fff;
  border-radius: 24px;
  padding: vh(24) vh(24) vh(18);
  // border: 1px solid red;
  box-shadow: 0px 0px 12px 0px #e8f0fb;

  .top {
    font-size: vh(18);
    color: #103268;
    display: flex;
    justify-content: space-between;

    .icon {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        transform: scale(0.66);
      }
    }
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;

    // border: 1px solid #000;
    &_title {
      color: #103268;
      font-size: vh(16);
      padding: 0 vh(8);
    }

    &_left {
      width: vw(16);
      height: vh(16);
      // border: 1px solid #000;
      text-align: center;
      line-height: vh(16);
      background: url(../../../../assets/calender-left_icon.png) no-repeat;
      background-size: vw(16) vw(16);
      background-position: center;
      // padding-right: 50px;
    }

    &_right {
      background: url(../../../../assets/calender-right_icon.png) no-repeat;
      background-size: vw(16) vw(16);
      background-position: center;
    }
  }

  .checkSchedule::-webkit-scrollbar {
    display: none;
  }

  .showSchedule {
    max-height: vh(350) !important;
    opacity: 1 !important;
  }

  .checkSchedule {
    transition: all 0.4s 0.05s ease-out;
    width: 400px;
    background: #fff;
    z-index: 222;
    padding: 0px vh(24) 8px vh(24);
    color: #103268;
    font-size: 12px;
    transform: translateX(vh(-25));
    max-height: 0px;
    opacity: 0;
    overflow: auto;
    box-shadow: var(--pm-main-box-shadow-bottom);

    .tip-title {
      font-size: vh(18);
      color: #103268;
      line-height: 30px;

      background: #fff;
      position: sticky;
      top: 0;
      z-index: 2;
      padding-left: 2px;
    }

    .event {
      color: rgba(16, 50, 104, 0.6);
      margin-bottom: 8px;
      line-height: 20px;
      font-size: 15px;
      background-color: #f5f5b795;
      border-radius: 8px;
      padding: 6px 12px;
      &:hover {
        background-color: #f5f5b7;
      }

      .task {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 4px 0;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      .content {
        line-height: 20px;
        color: rgba(16, 50, 104, 1);
        font-size: 13px;
        cursor: pointer;
        @include lineBreak(1);
        &:hover {
          color: var(--pm-main-menu-active-color);
        }
      }
    }
  }
}

::v-deep .el-input__inner {
  height: 28px;
  border: none;
  background: #f4f9ff;
  border-radius: 24px;
  padding: 0 12px;
  font-size: 12px;
  font-family: "HarmonyOS_Sans_SC" !important;
}

::v-deep .el-button {
  border: none;
  padding: 0;
  font-size: 10px;
  margin: 0;
  width: 48px;
  height: 24px;
  border-radius: 999px;
  color: #0d68fd;
  // line-height: -20px;
  background: linear-gradient(90deg,
      rgba(73, 167, 255, 0.1) 0%,
      rgba(71, 119, 255, 0.1) 100%);
}

::v-deep .el-button:hover {
  color: #0d68fd;
  background: linear-gradient(90deg,
      rgba(73, 167, 255, 0.1) 0%,
      rgba(71, 119, 255, 0.1) 100%);
}

::v-deep .el-button:nth-child(2) {
  margin-left: 8px;
  background: linear-gradient(90deg, #49a7ff 0%, #4777ff 100%);

  color: #fff;
}
</style>
<style lang='scss' scoped>
.spandate {
  // border: 1px solid #000;
}

.data-analysis {
  transition: all 0.3s;
  position: relative;
  height: vh(260);
  padding-top: 8px;
}

/*日历样式修改*/
::v-deep .el-calendar__header {
  display: none !important;
}

/*去掉原本背景颜色*/
.data-analysis ::v-deep .el-calendar {
  background: transparent;
}

::v-deep .el-calendar-table td:hover {
  background: transparent;
}

/*去掉选中背景颜色*/
.data-analysis ::v-deep .el-calendar-table td.is-selected {
  background: transparent;
}

/*修改每一小格大小*/
.data-analysis ::v-deep .el-calendar-table .el-calendar-day {
  position: relative;
  padding: 0px;
  // border: 1px solid #000;
  border-radius: 100%;
  width: vh(28);
  height: vh(28);
  line-height: vh(28);
  text-align: center;
  font-size: vh(12);
}

/*小红点样式*/
.data-analysis ::v-deep .el-badge {
  position: absolute;
  right: 5px;
  top: 5px;
  // border: 1px solid #000;
}

.data-analysis ::v-deep .el-calendar-table tbody tr td {
  padding-left: vh(15);
  padding-bottom: vh(5);
  // margin: 0 auto !important
  // display: none;
  // border: 1px solid blue !important;
}

// /*日历边框颜色*/
.data-analysis ::v-deep .el-calendar-table tr td:first-child {
  // border-left: 1px solid #666666;
  border: none;
}

.data-analysis ::v-deep .el-calendar-table tr:first-child td {
  // border-top: 1px solid #666666;
  border: none;
}

.data-analysis ::v-deep .el-calendar-table td {
  // border-bottom: 1px solid #666666;
  border-right: 1px solid #666666;
  border: none;
}

/*表格周一到周日颜色*/
.data-analysis ::v-deep .el-calendar-table thead th {
  color: #103268;
  font-size: vh(16);
  font-weight: bold;
  padding: 0;
  padding: vh(16) 0 vh(11);
}

/*头部日期颜色*/
.data-analysis ::v-deep .el-calendar__title {
  color: #68a8d0;
}

/*头部下面的横线*/
.data-analysis ::v-deep .el-calendar__header {
  border-bottom: 1px solid #666666;
}

/*鼠标悬停样式*/
.data-analysis ::v-deep .el-calendar-table .el-calendar-day:hover {
  cursor: default;
  background: transparent;
}

/*非本月字体颜色*/
.data-analysis ::v-deep .el-calendar-table:not(.is-range) td.next,
.el-calendar-table:not(.is-range) td.prev {
  color: #666666;
  cursor: pointer;
}

::v-deep .el-calendar__body {
  padding: 0;
  overflow: hidden;
}

::v-deep .el-calendar-table td.is-today .el-calendar-day {
  color: #103268;
  border: 5px solid #6be1c6;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }

  .activeday {
    width: vh(20);
    height: vh(20);
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: vh(20);
  }
}

.activeday {
  width: vh(28);
  height: vh(28);
  background: #e0edff;
  border-radius: 100%;
  position: absolute;
  &:hover {
    cursor: pointer;
    background: #cae1ff;
  }
}

.close {
  height: 0px;
  opacity: 0;
}

.event-box {
  position: fixed;
  min-height: 300px;
  top: 16px;
  right: vw(434);
  z-index: 99;
}
</style>