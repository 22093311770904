import request from '@/utils/request'

//任务列表
export function projectsTaskList (params){
    return request({
        url:`/api/projects/task/?project=${params}`,
        method:'get',
        // params
    })
}

//删除选中的任务
export function ganttDeleteTask (data) {
    return request({
        url:'/api/projects/task_detail/'+data.id+'/',
        method:'delete',
        // data
    })
}

//根据id 或者任务信息

export function  getTaskDetail (id){
    return  request ({
        url:'/api/projects/task_detail/'+id+'/',
        method:"get"
    })
}

// 添加协同任务
export function addCoworkTask (data){
    return request ({
        url:'/api/projects/cowork/',
        method:'post',
        data
    })
}

//删除协同任务
export function deleteCoworkTask (id){
    return request ({
        url:'/api/projects/cowork_detail/' +id+ '/',
        method:'delete',
    })
}

//获取协同任务
// /api/projects/cowork_detail/{id}
export function getCoworkTask (id){
    return request ({
        url:'/api/projects/task_detail/' +id+ '/',
        method:'get',
    })
}

//修改协同任务
export function putCoworkTask (data){
    return request ({
        url:'/api/projects/cowork_detail/' +data.id+ '/',
        method:'put',
        data
    })
}


//添加事件
export function setTimeEvent(data){
    return request({
        url:'/api/projects/timeevent/',
        method:'post',
        data
    })
}

//删除事件
export function deleteTimeEvent(data){
    return request({
        url:'/api/projects/timeevent/',
        method:'delete',
        data
    })
}

//编辑事件内容
export function timeevent_edit(data){
    return request({
        url:'/api/projects/timeevent_detail/'+ data.id +'/',
        method:'put',
        data
    })
}


//删除事件内容 /api/projects/timeevent_detail/{id}/
export function deleteEventContent(id){
    return request({
        url:'/api/projects/timeevent_detail/'+ id +'/',
        method:'delete',
    })
}

//暂停任务 /api/projects/taskpause/pause/
export function pauseTask(data){
    return request({
        url:'/api/projects/taskpause/pause/',
        method:'post',
        data
    })
}

//恢复暂停任务 /api/projects/taskpause/resume/ 
export function taskUnsuspend(data){
    return request({
        url:'/api/projects/taskpause/resume/',
        method:'post',
        data
    })
}

//获取事件对话参与人
export function getUserEVent(data){
    return request({
        url:'api/projects/timeevent-dot/oneday/',
        method:'post',
        data
    })
}