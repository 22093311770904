<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <!-- <router-view :key="key"></router-view> -->
      <keep-alive :include="cachedViews">
        <router-view></router-view>
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    // key() {
    //   return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
    // }
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  /*50 = navbar  */
  // min-height: calc(var(--vh, 1vh)*100 - 50px);
  height: 100%;
  width: 100%;
  overflow-y: auto;
  // width:1204px;
  position: relative;
  background-image: url(@/assets/maxMain_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // overflow: hidden;
  // margin-left: 48px;
  // border: 1px solid #000;
  // height:vh(1076);
  @include hideScrollbar;
}
.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    // min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>
<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
