import $store from "@/store";

export default {
  bind: function (el, binding, vnode) {
    const permissionMap = $store.state.permission.formatAllPermissions;
    const value = binding.value;
    let hasPermission;
    // 传入all时表示无需控制权限
    if (value === "all" || value === undefined || value === null) {
      hasPermission = true;
    } else if (Array.isArray(value)) {
      hasPermission = value.some((v) => permissionMap.has(v));
    } else if (typeof value === "string") {
      hasPermission = permissionMap.has(value);
    } else if (typeof value === "boolean") {
      hasPermission = value;
    } else {
      hasPermission = false;
      throw new Error(`permission value must be a string or an array, but got ${value}`);
    }

    if (!hasPermission) {
      el.style.pointerEvents = "none"; // 禁用鼠标事件
      el.style.opacity = 0.6; // 灰度化，可以通过滤镜更精细控制
      el.style.filter = "grayscale(100%)"; // CSS滤镜，完全灰度化
    }
  },
};
