import axios from 'axios'
import { Message } from 'element-ui'
import { getToken, getRefreshToken, setToken, setRefreshToken } from '@/utils/auth'
import router from '@/route/index'
import { removeToken } from '@/utils/auth'
import { MY_ORGANIZATION } from "@/constants/storageKey"

let baseURL = "";

if (process.env.NODE_ENV === "development") {
  baseURL = "http://pm.yichenghbkj.com:18706"
} else {
  baseURL = "https://pm.yichenghbkj.com"
}

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  baseURL: baseURL, // api的base_url
  // baseURL:'/parking',
  withCredentials: true,
  timeout: 50000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }

})

const refreshToken = (refresh_token) => {
  return new Promise((resolve, reject) => {
    axios.post(baseURL + '/api/oauth/refresh/', {
      refresh_token
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

//request拦截器
service.interceptors.request.use(config => {
  if (getToken()) {
    config.headers['Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  const orgId = JSON.parse(sessionStorage.getItem(MY_ORGANIZATION))?.oid;
  let t = router.currentRoute.meta.t;
  if (config.method == 'post') {
    let data = typeof config.data === 'string' ? JSON.parse(config.data) : config.data;
    config.data = {
      ...router.currentRoute.meta,
      ...data,
      oid: orgId
    }
  } else if (config.method == 'get') {
    config.params = {
      ...router.currentRoute.meta,
      ...config.params,
      oid: orgId
    }
  }
  return config
}, error => {
  // Do something with request error
  // console.log(error) // for debug
  // Promise.reject(error)
})

let isRefreshing = false;
let failedRequestsQueue = [];

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
    * code为非20000是抛错 可结合自己业务进行修改
    */
    const res = response.data
    // console.log('response...', response);
    // console.log('response...', response);
    if (res.code !== 200) {

      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      // res.code === 50008 || res.code === 50012 || res.code === 50014

      if (res.code == 410) {

      } else if (res.code == 401) {
        const config = response.config
        if (!isRefreshing) {
          isRefreshing = true;
          let refresh_token = getRefreshToken();
          if (refresh_token) {
            return refreshToken(refresh_token).then(res => {
              if (res.code == '200') {
                let newToken = res.data.token;
                let newRefreshToken = res.data.refresh_token;
                setToken(newToken);
                setRefreshToken(newRefreshToken);
                config.headers['Token'] = newToken
                failedRequestsQueue.forEach(cb => cb(newToken))
                failedRequestsQueue = []
                return service(config)
              } else {
                removeToken()
                router.push('/login');
              }

            }).catch(err => {
              removeToken()
              router.push('/login');
            }).finally(final => {
              isRefreshing = false;
            })
          } else {
            removeToken()
            router.push('/login');
          }
        } else {
          return new Promise((resolve) => {
            // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
            failedRequestsQueue.push((token) => {
              config.headers['Token'] = token
              resolve(service(config))
            })
          })
        }

        // removeToken()
        // router.push('/login');
        // MessageBox.confirm('已过期请重新登录，可以取消继续留在该页面，或者重新登录', '确定登出', {
        //   confirmButtonText: '重新登录',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('FedLogOut').then(() => {
        //     location.reload()// 为了重新实例化vue-router对象 避免bug
        //   })
        // })
      } else {
        Message.closeAll();
        Message({
          message: res.msg,
          type: 'error',
          duration: 5 * 1000
        })
      }
      console.log(' Promise.reject.....');
      // return
      return Promise.reject(res.code)
    } else {
      // console.log(' response.....', response.data);
      return response.data
    }
  },
  error => {
    // console.log('err' + error)// for debug
    // console.log('请求异常')
    // console.log(error)
    Message({
      message: '服务器请求异常',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
