import axios from "axios";
import  "spark-md5";
import SparkMD5 from "spark-md5";
let chunkSize = 1024 * 1024 * 10; // 10MB
let baseURL = 'https://dc.yichenghbkj.com:18001'
export function createChunks(file) {
    let chunks = [];
    let index = 0;
    let start = 0;
    while (start < file.size) {
        let chunk = file.slice(start, start + chunkSize);
        chunks.push({
            file: chunk,
            uploaded: false,
            index: index,
            fileName: file.name,
        })
        start += chunkSize;
        index++;
    }
    console.log('chunks...', chunks);
    return chunks;
}

const uploadHandler = (chunk) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', chunk.file);
        formData.append('index', chunk.index);
        formData.append('hash', chunk.hash);
        formData.append('name', chunk.fileName);
        formData.append('size', chunkSize);
        axios.post( baseURL + '/upload', formData).then(res => {
            // console.log('res...', res);
            chunk.uploaded = true;
            let uploadArr = JSON.parse(sessionStorage.getItem(chunk.hash)) || [];
            uploadArr.push(chunk.index);
            sessionStorage.setItem(chunk.hash, JSON.stringify(uploadArr));
            resolve(res);
        }).catch(err => {
            console.log('err...', err);
            reject(err)
        })
    })
}

const merge = (name, hash) => {
    return new Promise((resolve, reject) => {
        axios.post( baseURL + '/merge', {
            name,
            hash
        }).then(res => {
            console.log('merge res...', res);
            resolve(res);
        }).catch(err => {
            console.log('merge err...', err);
            reject(err)
        })
    })
}

const uploadChunks = async (chunks, maxRequest = 6) => {
    let uploadedArr = JSON.parse(sessionStorage.getItem(chunks[0].hash)) || [];
    chunks =chunks.filter(chunk => !uploadedArr.includes(chunk.index));
    return new Promise((resolve, reject) => {
        if (chunks.length == 0) {
            resolve([]);
        }
        let requestSliceArr = []
        let start = 0;
        while (start < chunks.length) {
            requestSliceArr.push(chunks.slice(start, start + maxRequest))
            start += maxRequest;
        }
        let index = 0;
        let requestReaults = [];
        let requestErrReaults = [];

        const request = async () => {
            if (index > requestSliceArr.length - 1) {
                let result = await merge(chunks[0].fileName,chunks[0].hash)
                console.log('chunks...', chunks);
                resolve(result)
                return;
            }
            let sliceChunks = requestSliceArr[index];
            Promise.all(
                sliceChunks.map(chunk => uploadHandler(chunk))
            ).then((res) => {
                requestReaults.push(...(Array.isArray(res) ? res : []))
                index++;
                request()
            }).catch((err) => {
                requestErrReaults.push(...(Array.isArray(err) ? err : []))
                reject(requestErrReaults)
            })
        }
        request()
    })
}

const checkFile = async (hash) => {
    return new Promise((resolve, reject) => {
        axios.post( baseURL + '/check', {
            hash
        }).then(res => {
            console.log('check res...', res);
            resolve(res?.data?.url);
        }).catch(err => {
            console.log('check err...', err);
            reject(err)
        })
    })
}

export function calculateHash(chunks) {
    const spark = new SparkMD5.ArrayBuffer();
    return new Promise((resolve, reject) => {
        const read = (i) => {
            if (i >= chunks.length) {
                resolve(spark.end());
                return;
            }
            const blob = chunks[i].file
            let reader = new FileReader();
            reader.onload = (e) => {
                const bytes = e.target.result
                spark.append(bytes)
                read(i + 1)
            }
            reader.readAsArrayBuffer(blob);
        }
        read(0)
    })
}

export async function uploadBigFile(file,callback) {
    let chunks = createChunks(file);
    let hash = await calculateHash(chunks);
    chunks = chunks.map(chunk => {
        return {
            hash,
            ...chunk
        }
    })
    let checkUrl = await checkFile(hash);
    if (checkUrl?.length > 0) {
        callback(checkUrl)
    } else {
        let res = await uploadChunks(chunks);
        callback(res?.data?.url)
    }

}